import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ApproveUpdateRequestApi,GetAllUpdateRequestUser,GetAllUsersByApprovePending, createUserByExcel,ApproveUserProfile,GetAllUsers, GetUSerDetails, GetAstroDetails, GetFamilyDeatils, GetCarrerDetails, GetPersonalityQuestions, GetGalleryDetails, VerifyByAdmin, GetGovtDocuments, GetPreferenceData, GetSpiritualDetail, DeletedUserById,getUserpendinglistDataexcel} from './api';

export const ApprovePendingUserList = createAsyncThunk('get approve pending user list', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllUsersByApprovePending();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const UpdateRequestUserList = createAsyncThunk('get approve update request user list', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllUpdateRequestUser();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const ApproveUpdateRequest = createAsyncThunk('ApproveUpdateRequest', async (formData, { rejectWithValue }) => {
  try {
    const response = await ApproveUpdateRequestApi(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const UserList = createAsyncThunk('get user list', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetAllUsers(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const ApproveUser = createAsyncThunk('ApproveUser', async (formData, { rejectWithValue }) => {
  try {
    const response = await ApproveUserProfile(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const DeleteUser = createAsyncThunk('DeleteUser', async (formData, { rejectWithValue }) => {
  try {
    const response = await DeletedUserById(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetDetails = createAsyncThunk('get GetDetails', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetUSerDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetAstro = createAsyncThunk('GetAstro', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetAstroDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetFamily = createAsyncThunk('GetFamily', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetFamilyDeatils(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetCarrer = createAsyncThunk('GetCarrerDetails', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetCarrerDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetPerosnality = createAsyncThunk('GetPersonalityQuestions', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetPersonalityQuestions(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetGallry = createAsyncThunk('GetGalleryDetails', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetGalleryDetails(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const VerifySelfie = createAsyncThunk('VerifySelfie', async (formData, { rejectWithValue }) => {
  try {
    const response = await VerifyByAdmin(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetGovtDocs = createAsyncThunk('GetGovtDocs', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetGovtDocuments(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetPref= createAsyncThunk('GetPref', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetPreferenceData(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const GetSpiritual= createAsyncThunk('GetSpiritual', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetSpiritualDetail(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const uploadUserExelData= createAsyncThunk('uploadUserExelData', async (formData, { rejectWithValue }) => {
  try {
    const response = await createUserByExcel(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const getUserpendinglistData= createAsyncThunk('getUserpendinglistData', async (_,{ rejectWithValue }) => {
  
  try {
    const response = await getUserpendinglistDataexcel();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const userSlice = createSlice({
  name: 'user',
  initialState: {
    error: null,
    success: false,
    successSelfie : false,
    errorSelfie : null,
    data: [],
    basic: {},
    astro: {},
    family: {},
    carrer : {},
    personality : {},
    about : {},
    selfi : {}, 
    photos : {},
    GovtDoc : {},
    pref :{}, 
    spritual : {},

  },
  reducers: {
    clearUserRes: state => {
        state.success = false;
        state.error = null;
        state.successSelfie = false;
        state.errorSelfie = null;
    },

  },
  extraReducers: builder => {
    builder
    .addCase(UpdateRequestUserList.pending, state => {
      state.error = null;
      state.success = false;
    })
    .addCase(UpdateRequestUserList.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.success = action.payload;
      state.error = action.payload;
    })
    .addCase(UpdateRequestUserList.rejected, (state, action) => {
      state.error = action.payload;
      state.success = false;
    })
    .addCase(ApprovePendingUserList.pending, state => {
      state.error = null;
      state.success = false;
    })
    .addCase(ApprovePendingUserList.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.success = action.payload;
      state.error = action.payload;
    })
    .addCase(ApprovePendingUserList.rejected, (state, action) => {
      state.error = action.payload;
      state.success = false;
    })
      .addCase(UserList.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(UserList.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(UserList.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(DeleteUser.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(DeleteUser.fulfilled, (state, action) => {
        state.success = action.payload;
        console.log('suceeesss', action.payload);
        state.error = false;
      })
      .addCase(DeleteUser.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetDetails.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetDetails.fulfilled, (state, action) => {
        state.basic = action.payload.data.data;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(GetDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetAstro.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetAstro.fulfilled, (state, action) => {
        state.astro = action.payload.data.data;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(GetAstro.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetFamily.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetFamily.fulfilled, (state, action) => {
        state.family = action.payload.data.data;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(GetFamily.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetCarrer.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetCarrer.fulfilled, (state, action) => {
        state.carrer = action.payload.data.data;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(GetCarrer.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetPerosnality.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetPerosnality.fulfilled, (state, action) => {
        state.personality = action.payload.data.data;
        state.about = action.payload.data.data2;
        state.success = action.payload;
        state.error = action.payload;
      })
      .addCase(GetPerosnality.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetGallry.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetGallry.fulfilled, (state, action) => {
        state.selfi = action.payload.data.data;
        state.photos = action.payload.data.data2;
        state.success = true;
        state.error = action.payload;
      })
      .addCase(GetGallry.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetGovtDocs.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetGovtDocs.fulfilled, (state, action) => {
        state.GovtDoc = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetGovtDocs.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetPref.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetPref.fulfilled, (state, action) => {
        state.pref = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetPref.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetSpiritual.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetSpiritual.fulfilled, (state, action) => {
        state.spritual = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetSpiritual.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(VerifySelfie.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(VerifySelfie.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(VerifySelfie.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(uploadUserExelData.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(uploadUserExelData.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(uploadUserExelData.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getUserpendinglistData.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(getUserpendinglistData.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(getUserpendinglistData.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      
  },
});
export const { clearUserRes } = userSlice.actions;
export default userSlice.reducer;

