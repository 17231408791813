import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { TabMenu } from 'primereact/tabmenu';
import 'react-tabs/style/react-tabs.css';
import Sidebar from "../../../Layouts/Sidebar";
import { userTabs } from "../../Data";
import Layout from "../../../Layouts/Layout";

import UserDetails from "../../Users/UserDetails";
import AstroDetails from "../../Users/AstroDetails";
import FamilyDetails from "../../Users/FamilyDetails";
import CarrerDetails from "../../Users/CarrerDetails";
import SpiritualDetails from "../../Users/SpritualDetails";
import PersonalityDetails from "../../Users/PersonalityDetails";
import Gallery from "../../Users/Gallery";
import Documents from "../../Users/Documents";
import PreferenceDetails from "../../Users/PreferenceDetails";


export default function Main() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [activeIndex, setActiveIndex] = useState(0);
    
    const components = [
        UserDetails,
        AstroDetails,
        FamilyDetails,
        CarrerDetails,
        SpiritualDetails,
        PersonalityDetails,
        PreferenceDetails,
        Gallery,
        Documents
        
    ];


    return (

        <>
        <Layout>
          <div className="grid">
            <div className="col-12">
              <div className="card">
              <TabMenu model={userTabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
              {React.createElement(components[activeIndex])}
              </div>
            </div>
          </div>
        </Layout>
       
      </>
        

    );
}
