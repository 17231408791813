import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetPerosnality, GetPref } from "../../../../Reducer/userSlice";


export default function PreferenceDetails() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.pref);
    // const data2 = useSelector(state => state.user.about);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);

    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetPref(dts));

    }, []);


    console.log('data', data);

    return (
        <>

            <div className="basic_sec">

                <h3>Partner's preferences</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Marital Status</label>
                        {data && data?.prefered_marital_status?.length > 0 ?
                            <ul>
                                {data?.prefered_marital_status.map((item, i) => (
                                    <li>{item.value}</li>
                                ))
                                }

                            </ul>
                            : "-"}
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Age Range</label><p>{data && data.prefered_age_start} - {data && data.prefered_age_end}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Height</label><p>{data && data.prefered_height_start} - {data && data.prefered_height_end}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How many children you wish to have after marriage</label><p>{data && data.children ? data.children : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Religion</label><p>{data && data.prefered_religion ? data.prefered_religion : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Caste</label><p>{data && data.prefered_caste ? data.prefered_caste : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Community</label><p>{data && data.prefered_community ? data.prefered_community : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Language</label>
                        {data && data?.prefered_language?.length > 0 ?
                            <ul>
                                {data?.prefered_language.map((item, i) => (
                                    <li>{item.value}</li>
                                ))
                                }

                            </ul>
                            : "-"}
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Country</label>
                        <p>{data && data.prefered_country ? data.prefered_country : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred State</label>
                        <p>{data && data.prefered_state ? data.prefered_state : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred City / Location</label>
                        <p>{data && data.prefered_city ? data.prefered_city : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Qualification</label>
                        <p>{data && data.prefered_qualification ? data.prefered_qualification : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Professional Background</label>
                        <p>{data && data.prefered_Proffessional_bkgrd ? data.prefered_Proffessional_bkgrd : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Annual Income</label>
                        <p>{data && data.prefered_income ? data.prefered_income : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Food Preferences</label>
                        {data && data?.prefered_food?.length > 0 ?
                            <ul>
                                {data.prefered_food.map((item, i) => (
                                    <li>{item.value}</li>
                                ))
                                }

                            </ul>
                            : "-"}
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Prefered Manglik Status</label>
                        <p>{data && data.prefered_manglik_status ? data.prefered_manglik_status : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Cultural Values and Practices</label>
                        <p>{data && data.prefered_cultr_val ? data.prefered_cultr_val : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Preferred Initiation Status</label>
                        <p>{data && data.prefered_initiation_status ? data.prefered_initiation_status : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">What is your preferred partner's affiliation</label>
                        {data && data?.prefered_affiliation?.length > 0 ?
                            <ul>
                                {data.prefered_affiliation.map((item, i) => (
                                    <li>{item.value}</li>
                                ))
                                }

                            </ul>
                            : "-"}                    </div>
                </div>

            </div>

        </>

    );
}
