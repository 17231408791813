import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import Swal from 'sweetalert2';
import { DelPlan, GetPlans, clearPlan } from "../../../../Reducer/SubscriptionSlice";
import Sidebar from '../../../Layouts/Sidebar';
import Layout from "../../../Layouts/Layout";
import DynamicDataTable from "../../../../Utils/DynamicDataTable";
import { Button } from 'primereact/button';
export default function AllPlans() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(state => state.plan.data);
    const success = useSelector(state => state.plan.success);
    const error = useSelector(state => state.plan.error);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearPlan());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearPlan());
            dispatch(GetPlans());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPlan());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearPlan());
        }
    }

    const columns = [
        {
            header: "Plan",
            field: "planName",
            wrap: true
        },
        {
            header: "Connections",
            field: "connectionsCount",
            wrap: true
        },
        {
            header: 'Messages',
            field: 'messagesCount',
            wrap: true
        },
        {
            header: 'Contacts',
            field: 'contactCount',
            wrap: true
        },
        {
            header: 'MatchMaker Recommendations',
            field: 'matchMakerRecommendation',
            wrap: true
        },
        {
            header: 'Validity of plan',
            field: 'Validity',
            wrap: true
        },
        {
            header: 'Price',
            field: 'price',
            wrap: true
        },
        {
            header: 'Action',
            button: true,
            width: "225px",


            body: (record) => {
                return (<>
                 <a onClick={() => edit(record)}>
            <i className="fa fa-edit" aria-hidden="true"></i>
            <Button label="edit" severity="info" icon="pi pi-edit" />
          </a>
                   <a onClick={() => DelRecord(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
                    
                    </>
                )

            }
        },

    ];

    function DelRecord(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DelPlan(data));
        }
    }


    function edit(data) {

        navigate('/SubscriptionPlans/Edit', { state: { data: data } });

    }


    useEffect(() => {

        dispatch(GetPlans());
    }, []);


    return (
        <>
            <Layout>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                    <h2>All Plans<span><Link to="/SubscriptionPlans/Add">Add</Link></span></h2>
                    <DynamicDataTable
                        data={data}
                        columns={columns}
                        loading={false}
                        emptyMessage="No customers found."
                    />
                    </div>
                </div>
                </div>
            </Layout>
           

        </>

    );
}
