import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactQueries, DeleteQuery } from './api';

export const GetAllQueries = createAsyncThunk('GetAllQueries', async (_, { rejectWithValue }) => {
    try {
        const response = await GetContactQueries();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelQuery = createAsyncThunk('DelQuery', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteQuery(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const querySlice = createSlice({
    name: 'query',
    initialState: {
        error: null,
        success: false,
        data: []
    },
    reducers: {
        clearQueryRes: state => {
            state.success = false;
            state.error = null;
        
          },

    },
    extraReducers: builder => {
        builder
            .addCase(GetAllQueries.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetAllQueries.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetAllQueries.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DelQuery.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DelQuery.fulfilled, (state, action) => {
                // state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DelQuery.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })


    },
});
export const { clearQueryRes } = querySlice.actions;
export default querySlice.reducer;

