import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
// import { GetDetails } from "../../../../Reducer/userSlice";
import Sidebar from "../../Layouts/Sidebar";
import { GetSingleMaker } from "../../../Reducer/matchMakerSlice";
import { API_BASE_URL } from "../../../Reducer/api";

export default function Details() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.maker.userdata);
    // const success = useSelector(state => state.user.success);
    // const error = useSelector(state => state.user.error);

    console.log('user details', data);

    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetSingleMaker(dts));



    }, []);



    return (

        <section id="full_page_dashboard" class="match_maker_width">
            <div class="container-fluid">
                <Sidebar />

                <section id="matchmaker_dashboard">
                    <div className="basic_sec">

                        <h3>Basic Info</h3>

                        <div class="row">
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Mobile:</label><p>{data && data.mobile}</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Email</label><p>{data && data.email}</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Name:</label><p>{data && data.basic && data.basic.name ? data.basic.name : "-"}</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Gender</label><p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                            </div>

                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Marital Status</label>
                                <p>{data && data.basic && data.basic.marital_status ? data.basic.marital_status : "-"}</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Mother Tongue</label>
                                <p>{data && data.basic && data.basic.mother_tounge ? data.basic.mother_tounge : "-"}</p>
                            </div>

                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Country</label>
                                <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>

                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">State</label>
                                <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>

                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">City</label>
                                <p>{data && data.basic && data.basic.city ? data.basic.city : "-"}</p>

                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Asscoiated Temple</label>
                                <p>{data && data.basic && data.basic.associated_temple ? data.basic.associated_temple : "-"}</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <label class="form-label">Iskon center</label>
                                <p>{data && data.basic && data.basic.Iskon_center ? data.basic.Iskon_center : "-"}</p>
                            </div>

                            <div class="row">
                                <label class="form-label">About</label>
                                <p>
                                    {data && data.basic && data.basic.about ? data.basic.about : "-"}
                                </p>

                            </div>

                            {data && data.basic && data.basic.images.length > 0 &&
                                <div class="row">
                                    {data.basic.images.map((item, i) => (
                                        <div class="col-lg-4 col-sm-6">
                                            <img src={API_BASE_URL + item} width="100px" height="100px" />
                                        </div>

                                    ))}

                                </div>
                            }
                        </div>
                    </div>
                </section>

            </div>
        </section>

    );
}
