import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetSpiritual } from "../../../../Reducer/userSlice";


export default function SpiritualDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.spritual);


    console.log('sprrrrrr', data);

    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetSpiritual(dts));

    }, []);



    return (
        <>

            <div className="basic_sec">

                <h3>Spiritual Info</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Are you associated/affiliated with any spiritual organization?</label><p>{data && data.affiliated_with_spiritual_organization}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How long are you affiliated with the organization?</label><p>{data && data.affiliated_duration}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How much time you spend daily on your spiritual sadhana?</label><p>{data && data.sadhanaTime ? data.sadhanaTime : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Since when have you been practicing Krishna Consciousness</label><p>{data && data.practicing_time_period ? data.practicing_time_period : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">On a daily basis, how many rounds of the Hare Krishna Mahamantra do you chant</label><p>{data && data.chanting_rounds ? data.chanting_rounds : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How often do you visit temple</label><p>{data && data.temple_visit ? data.temple_visit : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Spirituality's role in your life</label><p>{data && data.spirituality_role ? data.spirituality_role : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Who is your spiritual master?</label><p>{data && data.spiritual_master ? data.spiritual_master : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Are you initiated?</label>
                        <p>{data && data.initiated ? data.initiated : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Initiation Name</label>
                        <p>{data && data.Initiation_name ? data.Initiation_name : "-"}</p>

                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Do you follow Four Regulative Principles</label>
                        <p>{data && data.four_Regulative_Principles ? data.four_Regulative_Principles : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Do your parents practice Krishna Consciousness</label>
                        <p>{data && data.parents_practice_Krishna ? data.parents_practice_Krishna : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">List of Prabhupad's book read by you.</label>
                        {data && data.selectedBooks && data.selectedBooks.length > 0 ?
                            <ul>

                                {data.selectedBooks.map((item, i) => (

                                    <li>{item.value}</li>


                                ))}</ul>
                            : "-"}
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">What Srila Prabhupada book are you currently reading</label>
                        <p>{data && data.currently_reading ? data.currently_reading : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Do you observe fasting on Ekadashi days</label>
                        <p>{data && data.ekadashi_fasting ? data.ekadashi_fasting : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Which ISKCON/HKM Centre are you associated with</label>
                        <p>{data && data.iskcon_associted_with ? data.iskcon_associted_with : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How are you associated with the temple</label>
                        <p>{data && data.temple_associated_type ? data.temple_associated_type : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Have you attended any spiritual retreats, seminars, or workshops related to Krishna Consciousness</label>
                        <p>{data && data.attended_seminar ? data.attended_seminar : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Are you open to engaging in devotional services or seva together as a couple</label>
                        <p>{data && data.engaged_devotional_services ? data.engaged_devotional_services : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How important is living in close proximity to a temple or devotional community for you</label>
                        <p>{data && data.living_in_temple ? data.living_in_temple : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Are you open to further deepening your understanding and practice of Krishna Consciousness after marriage
                        </label>
                        <p>{data && data.Krishna_Consciousness_after_marriage ? data.Krishna_Consciousness_after_marriage : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">What is your understanding and acceptance of the principles and teachings of Srila Prabhupada

                        </label>
                        <p>{data && data.tech_of_Srila_Prabhupada ? data.tech_of_Srila_Prabhupada : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How do you envision supporting and encouraging each other's spiritual growth within the marriage?
                        </label>
                        <p>{data && data.spritulGrowthMarriage ? data.spritulGrowthMarriage : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How do you envision incorporating Krishna Consciousness into your married life?
                        </label>
                        <p>{data && data.incorporating_Krishna_Consciousness ? data.incorporating_Krishna_Consciousness : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How do you handle challenges or conflicts in your spiritual journey?
                        </label>
                        <p>{data && data.conflicts_spiritualJourny ? data.conflicts_spiritualJourny : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Do you have any specific spiritual goals or aspirations that you would like to share?
                        </label>
                        <p>{data && data.specific_spiritual_goals ? data.specific_spiritual_goals : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Do you have a spiritual mentor or guide? If yes, please provide details.?
                        </label>
                        <p>{data && data.spiritual_master ? data.spiritual_master : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">What temple service are you engaged in?
                        </label>
                        <p>{data && data.temple_services ? data.temple_services : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Department Name?
                        </label>
                        <p>{data && data.department_name ? data.department_name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Designation?
                        </label>
                        <p>{data && data.designation_name ? data.designation_name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">What are the various roles and experiences you have had in your service at the temple?
                        </label>
                        <p>{data && data.roles_and_experiences ? data.roles_and_experiences : "-"}</p>
                    </div>
                </div >

            </div >
        </>

    );
}
