import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllMatchMakers, ApproveMatchMakerProfile, DeleteMatchMaker, GetMatchMakerDetail } from './api';

export const MatchMakerList = createAsyncThunk('get user list', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllMatchMakers();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const ApproveMatchMaker = createAsyncThunk('ApproveMatchMaker', async (formData, { rejectWithValue }) => {
    try {
        const response = await ApproveMatchMakerProfile(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const Delmatchmaker = createAsyncThunk('Delmatchmaker', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteMatchMaker(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetSingleMaker = createAsyncThunk('GetSingleMaker', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetMatchMakerDetail(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});






const matchMakerSlice = createSlice({
    name: 'maker',
    initialState: {
        data: [],
        error: null,
        success: false,
        userdata : null

    },
    reducers: {
        clearMakerRes: state => {
            state.success = false;
            state.error = null;

        },

    },
    extraReducers: builder => {
        builder
            .addCase(MatchMakerList.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(MatchMakerList.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = action.payload;
            })
            .addCase(MatchMakerList.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(ApproveMatchMaker.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(ApproveMatchMaker.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = action.payload;
            })
            .addCase(ApproveMatchMaker.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetSingleMaker.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetSingleMaker.fulfilled, (state, action) => {
                state.userdata = action.payload.data.data;
                state.success = action.payload;
                state.error = action.payload;
            })
            .addCase(GetSingleMaker.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })

    },
});
export const { clearMakerRes } = matchMakerSlice.actions;
export default matchMakerSlice.reducer;

