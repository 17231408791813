import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { UpdatePlan, clearPlan } from "../../../../Reducer/SubscriptionSlice";

export default function EditPlan() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state.data;
    const [inputField, setInputField] = useState(stateData);
    const success = useSelector(state => state.plan.success);
    const error = useSelector(state => state.plan.error);


    useEffect(() => {

    }, []);


    const inputsHandler = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            let form = new FormData();
            form.append('planName', inputField.planName);
            form.append('connectionsCount', inputField.connectionsCount);
            form.append('messagesCount', inputField.messagesCount);
            form.append('contactCount', inputField.contactCount);
            form.append('matchMakerRecommendation', inputField.matchMakerRecommendation);
            form.append('Validity', inputField.Validity);
            form.append('price', inputField.price);
            form.append('id', inputField._id);
            dispatch(UpdatePlan(form));
        } catch (e) {

            console.error('Error during login:', e);

        };
    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearPlan());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearPlan());
            navigate("/SubscriptionPlans");
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPlan());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearPlan());
        }
    }


    return (
        <>
            <section id="full_page_dashboard" class="match_maker_width">
                <div class="container-fluid">
                    <Sidebar />
                    <section id="matchmaker_dashboard">
                        <h2>Edit Plan</h2>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div class="row">
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Plan Name</label>
                                        <input type="text" class="form-control" onChange={inputsHandler} name="planName" required defaultValue={inputField.planName} />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Connections</label>
                                        <input type="text" min="0" class="form-control" onChange={inputsHandler} name="connectionsCount" required defaultValue={inputField.connectionsCount} />

                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Messages</label>
                                        <input type="text" min="0" class="form-control" onChange={inputsHandler} name="messagesCount" required defaultValue={inputField.messagesCount} />
                                    </div>

                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Contact</label>
                                        <input type="text" min="0" class="form-control" onChange={inputsHandler} name="contactCount" required defaultValue={inputField.contactCount} />

                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">MatchMaker Recommendations</label>
                                        <input type="text" min="0" class="form-control" onChange={inputsHandler} name="matchMakerRecommendation" required defaultValue={inputField.matchMakerRecommendation} />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Plan validity</label>
                                        <input type="number" class="form-control" onChange={inputsHandler} name="Validity" required defaultValue={inputField.Validity} />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Price</label>
                                        <input type="number" min="0" class="form-control" onChange={inputsHandler} name="price" required defaultValue={inputField.price} />
                                    </div>
                                </div>
                                <div>
                                    <button class="btn" type="Submit">Update</button>
                                </div>
                            </div>
                        </form>
                    </section>

                </div>

            </section>

        </>

    );
}
