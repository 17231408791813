import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetPerosnality } from "../../../../Reducer/userSlice";


export default function PersonalityDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.personality);
    const data2 = useSelector(state => state.user.about);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);

    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetPerosnality(dts));

    }, []);



    return (
        <>

            <div className="basic_sec">

                <h3>Personality Info</h3>

                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">How do you handle stressful situations or conflicts?</label>
                        
                        <ul>
                            {data && data.stresful_situation && data.stresful_situation.length > 0 ? data.stresful_situation.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Can you share an example of a challenging situation you faced and how you dealt with it?</label><p>{data && data.challenging_situation ? data.challenging_situation : "-"}</p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">What are your favorite ways to spend your free time?</label>
                        <ul>
                            {data && data.free_time && data.free_time.length > 0 ? data.free_time.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">What kind of books or movies do you enjoy?</label>
                        <ul>
                            {data && data.movies_books && data.movies_books.length > 0 ? data.movies_books.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Strength</label>
                        <ul>
                            {data && data.strength && data.strength.length > 0 ? data.strength.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Waekness</label>
                        <ul>
                            {data && data.weakness && data.weakness.length > 0 ? data.weakness.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">What motivates and inspires you in life?</label>
                        <ul>
                            {data && data.motivate_insipre && data.motivate_insipre.length > 0 ? data.motivate_insipre.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                        
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">How would you characterize your personality?</label>
                        <ul>
                            {data && data.characterize_your_personality && data.characterize_your_personality.length > 0 ? data.characterize_your_personality.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Are you more comfortable in social gatherings?*</label><p>{data && data.social_Gatherings ? data.social_Gatherings : "-"}</p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Lifestyle</label>
                        <ul>
                            {data && data.lifestyle && data.lifestyle.length > 0 ? data.lifestyle.map((lang, i) => (
                                <li>{lang}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Diet</label>
                        <p>{data && data.diet ? data.diet : "-"}</p>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">What are your long-term goals and aspirations?</label>
                        <ul>
                            {data && data.goals_and_aspirations && data.goals_and_aspirations.length > 0 ? data.goals_and_aspirations.map((lang, i) => (
                                <li key={i}>{lang}</li>
                            )) : "-"
                            }
                        </ul>

                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Elaborate on your vision for the future and the steps you plan to take to achieve your long-term goals and aspirations.</label>
                        <p>{data && data.elaborate_goals ? data.elaborate_goals : "-"}</p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">How do you handle criticism or feedback from others?</label>
                        <p>{data && data.criticism_or_feedback ? data.criticism_or_feedback : "-"}</p>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Describe a specific instance where you received feedback and explain how you processed and utilized it for your personal growth and development.</label>
                        <p>{data && data.personal_growth_development ? data.personal_growth_development : "-"}</p>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Do you prefer a structured routine?</label>
                        <p>{data && data.structured_routine ? data.structured_routine : "-"}</p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Discuss your approach to balancing structure and
                            flexibility in your daily life, highlighting the benefits and challenges you
                            face with each approach.</label>
                        <p>{data && data.benefits_and_challenges ? data.benefits_and_challenges : "-"}</p>
                    </div>

                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">About Yourself</label>
                        <p>{data2 && data2.about ? data2.about : "-"}</p>
                    </div>

                </div>


            </div>

        </>

    );
}
