import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import Sidebar from "../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { AddInstructor, clearResInst } from "../../../../Reducer/PagesContentSlices/instructorSlice";
import { AddFaqs, clearfq } from "../../../../Reducer/PagesContentSlices/faqSlice";
import Layout from "../../../Layouts/Layout";
const faqCategory = [
  { name: 'Profile Management', level: 'Profile Management' },
  { name: 'Contacting & responding to Members', level: 'Contacting & responding to Members' },
  { name: 'Searching and Filtering Profiles', level: 'Searching and Filtering Profiles' },
  { name: 'Login & Password', level: 'Login & Password' },
  { name: 'Getting started:Registration', level: 'Getting started:Registration' },
  { name: 'Payment', level: 'Payment' },
 
];
export default function Add() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.faq.success);
    const error = useSelector(state => state.faq.error);
    const [formData, setFormData] = useState({
      category: "Profile Management",
    });
   
    useEffect(() => {

    },);

    function handleChange(e) {

      setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });


    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            let formData1 = new FormData();
            formData1.append('category', formData.category.level);
            formData1.append('question', formData.question);
            formData1.append('content', formData.content);
            dispatch(AddFaqs(formData1));
        } catch (e) {

            console.error('Error during login:', e);

        };
    }

    if (success && success == true) {
        Swal.fire({
            title: 'Congratulation!',
            text: 'Added Successfully',
            icon: 'success'
        });
        dispatch(clearfq());
        setTimeout(() => {
            navigate('/Pages/FAQs');
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearfq());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearfq());
        }
    }

    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add Instructor</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-12">
                    <label htmlFor="firstname2">Category</label>
                    <Dropdown value={formData.category} onChange={handleChange} options={faqCategory} optionLabel="name" 
                        placeholder="Select Category" className="w-full md:w-14rem"  name="category" />
                   
                    
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">question</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      value={formData && formData.question}
                      onChange={(e) => handleChange(e)} name="question" required 
                    />
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Title</label>
                    <InputTextarea
                      id="lastname2"
                      type="text"
                      onChange={handleChange}
                      name="content"
                      value={formData && formData.content}
                      required
                    />
                  </div>
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </Layout>
            

        </>

    );
}
