import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addStories, getAllStories, deleteStory, editStories } from '../api';


export const AddStory = createAsyncThunk('add storyyyyy', async (formData, { rejectWithValue }) => {
    try {
      const response = await addStories(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const GetStories = createAsyncThunk('get storiesss', async (_, { rejectWithValue }) => {
    try {
      const response = await getAllStories();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const DelStory = createAsyncThunk('del DelStory', async (formData, { rejectWithValue }) => {
    try {
      const response = await deleteStory(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const EditSto = createAsyncThunk('editt storrrrrr', async (formData, { rejectWithValue }) => {
    try {
      const response = await editStories(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

const storySlice = createSlice({
  name: 'story',
  initialState: {
    error: null,
    success: false,
    data : null
   
  },
  reducers: {
    clearStRes: state => {
      state.success = false;
      state.error = null;
    },

  },
  extraReducers: builder => {
    builder
    .addCase(AddStory.pending, state => {
      state.error = null;
      state.success = false;
    })
    .addCase(AddStory.fulfilled, (state, action) => {
      state.success = true;
      state.error = null;
    })
    .addCase(AddStory.rejected, (state, action) => {
      state.error = action.payload;
      state.success = false;
    })
    .addCase(GetStories.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetStories.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetStories.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(DelStory.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(DelStory.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(DelStory.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(EditSto.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(EditSto.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
      })
      .addCase(EditSto.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
   
  },
});
export const { clearStRes } = storySlice.actions;
export default storySlice.reducer;

