import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import Swal from 'sweetalert2';
import { GetReported } from "../../../Reducer/reportedSlice";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";

export default function ReportedUsers() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.report.data);


    const columns = [
        {
            header: "Report By",
            field: "blockedByName",
            wrap: true
        },
        {
            header: "Email",
            field: "blockedUserEmail",
            wrap: true
        },
        {
            header: "Mobile",
            field: "blockedUserMobile",
            wrap: true
        },
        {
            header: "Reported User",
            field: "blockedByName",
            wrap: true
        },
        {
            header: "Reported User Email",
            field: "blockedByEmail",
            wrap: true
        },
        {
            header: "Reported User Mobile",
            field: "blockedByMobile",
            wrap: true
        },
        {
            header: 'Reason of report',
            field: 'reason',
            wrap: true
        },

    ];


    useEffect(() => {
        dispatch(GetReported());
    }, []);


    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Report Section</h5>
              <DynamicDataTable
                data={data}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
              />
            </div>
          </div>
        </div>
        </Layout>
            

        </>

    );
}
