import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

import Sidebar from "../../../Layouts/Sidebar";
import { AddHome, GetHome, clearStatusHome } from "../../../../Reducer/PagesContentSlices/homeSlice";
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../../../Reducer/api";
import { GetAboutUsPage, UpdateAboutUs, clearStatusAbout } from "../../../../Reducer/PagesContentSlices/aboutSlice";
import Layout from "../../../Layouts/Layout";
import DynamicDataTable from "../../../../Utils/DynamicDataTable";
export default function About() {
    const dispatch = useDispatch();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const [file4, setFile4] = useState();
    const [data, setData] = useState({});
    const success = useSelector(state => state.about.success);
    const error = useSelector(state => state.about.error);

    useEffect(() => {
     getData();
    }, []);

    async function getData(){
        let res = await dispatch(GetAboutUsPage());
        if(res.payload && res.payload.data.data){
            setData(res.payload.data.data);
        }
    }

    function handleChange(e) {
        if (e.target.name === "image") {
            setData({
                ...data,
                [e.target.name]: e.target.files[0]
            });
            setFile1(URL.createObjectURL(e.target.files[0]));
        }
        else if (e.target.name === "img1") {
            setData({
                ...data,
                [e.target.name]: e.target.files[0]
            });
            setFile2(URL.createObjectURL(e.target.files[0]));
        }
        else if (e.target.name === "img2") {
            setData({
                ...data,
                [e.target.name]: e.target.files[0]
            });
            setFile3(URL.createObjectURL(e.target.files[0]));
        }
        else if (e.target.name === "img3") {
            setData({
                ...data,
                [e.target.name]: e.target.files[0]
            });
            setFile4(URL.createObjectURL(e.target.files[0]));
        }
        else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {

            let formData = new FormData();
            formData.append('image', data.image);
            formData.append('img1', data.img1);
            formData.append('img2', data.img2);
            formData.append('img3', data.img3);
            formData.append('approach_content', data.approach_content);
            formData.append('cnt1', data.cnt1);
            formData.append('cnt2', data.cnt2);
            formData.append('cnt3', data.cnt3);
            formData.append('content', data.content);
            formData.append('head1', data.head1);
            formData.append('head2', data.head2);
            formData.append('head3', data.head3);
            formData.append('text1', data.text1);
            formData.append('text2', data.text2);
            formData.append('text3', data.text3);
            formData.append('text4', data.text4);
            formData.append('heading1', data.heading1);
            formData.append('heading2', data.heading2);
            formData.append('heading3', data.heading3);
            formData.append('heading4', data.heading4);
            formData.append('meta_tag', data.meta_tag);
            formData.append('meta_description', data.meta_description);
            formData.append('slug', data.slug);
            dispatch(UpdateAboutUs(formData));
        } catch (e) {

            console.error('Error during login:', e);

        };
    }

    if (success && success.status == 200) {
        if (success.data.data) {
            console.log('success underr data', success);
            dispatch(clearStatusAbout());
        } else {
            Swal.fire({
                title: 'Success!',
                text: 'Data updated successfully',
                icon: 'success'
            });
            console.log('success underr data', success);
            dispatch(clearStatusAbout());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatusAbout());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearStatusAbout());
        }
    }

    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Home Page</h5>
              
                  <form onSubmit={handleSubmit}>
                    <div className="p-fluid formgrid grid">
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Image</label>
                        <InputText
                          id="firstname2"
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file1 ? (
                          <img src={file1} height={200} width={480} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL +  data.image}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Content</label>
                        <InputTextarea
                          type="text"
                          defaultValue={data && data.content} name="content" onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Heading 1</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="heading1" defaultValue={data && data.heading1} onChange={(e) => handleChange(e)}
                        />
                      
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Text 1</label>
                        <InputText
                          id="firstname2"
                          type="text"
                          name="text1"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.text1}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Heading 2</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="heading2" defaultValue={data && data.heading2} onChange={(e) => handleChange(e)}
                        />
                      
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 2</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="text2"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.text2}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Heading 3</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="heading3" defaultValue={data && data.heading3} onChange={(e) => handleChange(e)}
                        />
                      
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 3</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="text3"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.text3}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Heading 4</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="heading4" defaultValue={data && data.heading4} onChange={(e) => handleChange(e)}
                        />
                      
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 4</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="text4"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.text4}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Approach Heading</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="approach_heading" defaultValue={data && data.approach_heading} onChange={(e) => handleChange(e)}
                        />
                      
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Approach Content</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="approach_content"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.approach_content}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Image 1</label>
                        <InputText
                          id="firstname2"
                          type="file"
                          name="img1"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file2 ? (
                          <img src={file2} height={200} width={480} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + data.img1}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Head 1</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="head1"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.head1}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Content 1</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="cnt1"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.cnt1}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Image 2</label>
                        <InputText
                          id="lastname2"
                          type="file"
                          name="img2"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file3 ? (
                          <img height={200} width={480} src={file3} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + data.img2}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Head 2</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="head2"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.head2}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Content 2</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="cnt2"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.cnt2}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Image 3</label>
                        <InputText
                          id="lastname2"
                          type="file"
                          name="img3"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file4 ? (
                          <img height={200} width={480} src={file4} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + data.img3}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Head 3</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="head3"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.head3}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Content 3</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="cnt3"
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.cnt3}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Tag</label>
                        <InputText
                          type="text"
                          name="meta_tag"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.meta_tag}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Description</label>
                        <InputTextarea
                          type="text"
                          name="meta_description"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.meta_description}
                        />
                      </div>

                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Slug</label>
                        <InputText
                          type="text"
                          name="slug"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={data.slug}
                        />
                      </div>

                      <div class="col-12">
                        <div className="md:col-3">
                          <Button
                            label="Submit"
                            type="submit"
                            severity="Primary"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                
            </div>
          </div>
        </div>
        
        </Layout>
            

        </>

    );
}
