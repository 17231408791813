import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import Sidebar from "../../../Layouts/Sidebar";
import { SavePlan, clearPlan } from "../../../../Reducer/SubscriptionSlice";
import Layout from "../../../Layouts/Layout";

export default function AddPlan() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
    });
    const success = useSelector(state => state.plan.success);
    const error = useSelector(state => state.plan.error);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearPlan());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearPlan());
            navigate("/SubscriptionPlans");
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPlan());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearPlan());

        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let form = new FormData();
        form.append('planName', formData.planName);
        form.append('connectionsCount', formData.connectionsCount);
        form.append('messagesCount', formData.messagesCount);
        form.append('contactCount', formData.contactCount);
        form.append('matchMakerRecommendation', formData.matchMakerRecommendation);
        form.append('Validity', formData.Validity);
        form.append('price', formData.price);
        dispatch(SavePlan(form));
    }


    useEffect(() => {
    }, []);

    return (
        <>
        <Layout>
        <section id="full_page_dashboard" class="match_maker_width">
                <div class="container-fluid">
                 
                    <section id="matchmaker_dashboard">
                        <h2>Add New Plan</h2>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div class="row">
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Plan Name</label>
                                        <input type="text" class="form-control" onChange={handleInputChange} name="planName" required />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Connections</label>
                                        <input type="test" min="0" class="form-control" onChange={handleInputChange} name="connectionsCount" required />

                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Messages</label>
                                        <input type="test" min="0" class="form-control" onChange={handleInputChange} name="messagesCount" required />
                                    </div>

                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Contact</label>
                                        <input type="text" min="0" class="form-control" onChange={handleInputChange} name="contactCount" required />

                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">MatchMaker Recommendations</label>
                                        <input type="text" min="0" class="form-control" onChange={handleInputChange} name="matchMakerRecommendation" required />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Plan validity(in months)</label>
                                        <input type="number" class="form-control" onChange={handleInputChange} name="Validity" required />
                                    </div>
                                    <div class="col-lg-6 col-sm-3 form-group">
                                        <label class="form-label">Price</label>
                                        <input type="number" min="0" class="form-control" onChange={handleInputChange} name="price" required />
                                    </div>
                                </div>
                                <div>
                                    <button class="btn" type="Submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </section>

                </div>

            </section>
        </Layout>
          

        </>

    );
}
