import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddInstructors, GettAllInstructors, DeleteInstructor, UpdateInstructors } from '../api';


export const AddInstructor = createAsyncThunk('AddInstructor', async (formData, { rejectWithValue }) => {
    try {
        const response = await AddInstructors(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetInstructor = createAsyncThunk('GetInstruct', async (_, { rejectWithValue }) => {
    try {
        const response = await GettAllInstructors();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelInstructor = createAsyncThunk('DelInstructor', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteInstructor(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const EditInstructor = createAsyncThunk('EditInstructor', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateInstructors(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const instructorSlice = createSlice({
    name: 'instructor',
    initialState: {
        error: null,
        success: false,
        data: null

    },
    reducers: {
        clearResInst: state => {
            state.success = false;
            state.error = null;
        },

    },
    extraReducers: builder => {
        builder
            .addCase(AddInstructor.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(AddInstructor.fulfilled, (state, action) => {
                state.success = true;
                state.error = null;
            })
            .addCase(AddInstructor.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetInstructor.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetInstructor.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetInstructor.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DelInstructor.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DelInstructor.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DelInstructor.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(EditInstructor.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(EditInstructor.fulfilled, (state, action) => {
                state.success = true;
                state.error = null;
            })
            .addCase(EditInstructor.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })

    },
});
export const { clearResInst } = instructorSlice.actions;
export default instructorSlice.reducer;

