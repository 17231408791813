import React,{useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
const DynamicDataTable = ({ data, columns, loading, emptyMessage }) => {
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
       
    });
    const fields = columns
    .filter(col => col.field) 
    .map(col => col.field);
    

    const onGlobalFilterChange = (e) => {
        
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
      };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    
    return (
        <DataTable 
        value={data}   
        loading={loading} 
        emptyMessage={emptyMessage}
        paginator rows={25}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        header={renderHeader()}
        globalFilterFields={fields}
        filters={filters}
        stripedRows
        >
            {columns.map((column, index) => (
                <Column  key={index} {...column} />
            ))}
        </DataTable>
    );
};

export default DynamicDataTable;
