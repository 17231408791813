import axios from 'axios';

// export const API_BASE_URL = 'https://api.vedicmarriage.ai/';
// export const API_BASE_URL="http://localhost:5800/";
let API_BASE_URL;

if (process.env.NODE_ENV === 'development') {
  API_BASE_URL = 'http://localhost:5800/';
} else if (process.env.NODE_ENV === 'production') {
  API_BASE_URL = 'https://api.vedicmarriage.ai/';
}
export { API_BASE_URL };
export const loginAdmin = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/adminLogin`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ChangePassword = async (formData) => {
  try {
    
    const response = await axios.post(`${API_BASE_URL}admin/ChangePassword`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ApproveUserProfile = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/ApproveUserProfile`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ApproveUpdateRequestApi = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/ApproveUpdateRequest`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const AddResources = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddResources`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getAllResource = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/AllResources`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DelResource = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteResource`, formData);
    console.log('response', response);
    return response;
  } catch (error) {
    throw error;
  }
}

export const editResource = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateResources`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addStories = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/addStories`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAllStories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/AllStories`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteStory = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteStories`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const editStories = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateStories`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const AddHomePage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddHomePage`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetHomePage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetHomePage`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const createUserByExcel = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/createUserByExcel`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getUserpendinglistDataexcel = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/getUserpendinglistDataexcel`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddSliderData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddSliderData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DeleteSlide = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteSlide`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const AllSidesData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/AllSidesData`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddTranscendsData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddTranscendsData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AllTranscends = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/AllTranscends`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const UpdateTranscendsData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateTranscendsData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const DeleteTranscends = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteTranscends`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAllUsers = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetAllUsers`,formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllUsersByApprovePending = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllUsersByApprovePending`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllUpdateRequestUser = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllUpdateRequestUser`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DeletedUserById = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeletedUserById`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetUSerDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetUserDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAstroDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetAstroDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetFamilyDeatils = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetFamilyDeatils`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetCarrerDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetCarrerDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetPersonalityQuestions = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetPersonalityQuestions`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetGalleryDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetGalleryDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const VerifyByAdmin = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/VerifyByAdmin`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetGovtDocuments = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetGovtDocuments`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetPreferenceData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetPreferenceData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetSpiritualDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetSpiritualDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetDashboardLIst = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetDashboardLIst`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const MaleFemaleDataCount = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/MaleFemaleDataCount`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const PaidFreeUserCount = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/PaidFreeUserCount`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetUserStatusCount = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetUserStatusCount`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddKootas = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddKootas`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetKundaliPage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetKundaliPage`, formData);
    return response
  } catch (error) {
    throw error;
  }
}


export const DeleteGun = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteGun`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const UpdateKundaliPage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateKundaliPage`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAboutPage = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAboutPage`);
    return response
  } catch (error) {
    throw error;
  }
}

export const UpdateAboutUsPage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateAboutUsPage`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GettAllInstructors = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GettAllInstructors`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddInstructors = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddInstructors`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DeleteInstructor = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteInstructor`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const UpdateInstructors = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateInstructors`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddFAQ = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddFAQ`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAllFAQ = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllFAQ`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const DeleteFaq = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteFaq`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetContactQueries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetContactQueries`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DeleteQuery = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteQuery`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllApprovedMatchMakers = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetAllApprovedMatchMakers
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const AssignUserToMatchMaker = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AssignUserToMatchMaker
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllReportedUsers = async (formData) => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllReportedUsers
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const CouponAdd = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/CouponAdd
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const DeleteCoupon = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteCoupon
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAllCoupons = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllCoupons
    `);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllSubscriptionPlan = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllSubscriptionPlan
    `);
    return response;
  } catch (error) {
    throw error;
  }
}

export const DeleteSubscriptionPlan = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteSubscriptionPlan
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const AddSubscriptionPlan = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/AddSubscriptionPlan
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const UpdateSubscriptionPlan = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/UpdateSubscriptionPlan
    `, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetAllMatchMakers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}admin/GetAllMatchMakers`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ApproveMatchMakerProfile = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/ApproveMatchMakerProfile`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const DeleteMatchMaker = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/DeleteMatchMaker`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetMatchMakerDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}admin/GetMatchMakerDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}








