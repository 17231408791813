import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetGallry, VerifySelfie, clearUserRes } from "../../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../../Reducer/api";


export default function Gallery() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.selfi);
    const data2 = useSelector(state => state.user.photos);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);

    useEffect(() => {
     getData();

    }, []);

    async function getData(){
        let dts = { uid: params.id };
        dispatch(GetGallry(dts));
    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearUserRes());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.message,
                icon: 'success'
            });
            dispatch(clearUserRes());
            getData();

        }


    }



    if (error && error.status !== 200) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearUserRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearUserRes());
        }
    }

    const verifySelfie = (type) => {
        console.log('aaaaaa');
        let res = { uid: params.id, type: type };
        dispatch(VerifySelfie(res));

    }

    return (
        <>

            <div className="basic_sec">

                <h3>Uploaded Selfie</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        {data && data.img ? <img src={API_BASE_URL + data.img} alt="" height="200px" /> : <p>Selfie not uploaded</p>}
                    </div>
                    {data && data.verified === true && <button class="btn">Verified</button>}
                    {data && data.verified === false && <button class="btn" onClick={() => verifySelfie('selfie')}>Click to verify</button>}

                </div>
                <div class="row">
                    <h3>Uploaded Photos</h3>
                    {data2 && data2.photos && data2.photos.length > 0 ?
                        <>
                            {data2.photos.map((item, i) => (
                                <div class="col-lg-4 col-sm-6">
                                    <img src={API_BASE_URL + item} alt="img"  />
                                </div>
                            ))}
                        </>

                        : <p>No uploads found</p>}
                    {data2 && data2.verified === true && <button class="btn">Verified</button>}
                    {data2 && data2.verified === false && <button class="btn" onClick={() => verifySelfie("photos")}>Click to verify</button>}


                </div>


            </div>


        </>

    );
}
