import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetAstro } from "../../../../Reducer/userSlice";


export default function AstroDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.astro);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);


    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetAstro(dts));

    }, []);

    console.log('astro.', data);



    return (
        <>

            <div className="basic_sec">

                <h3>Astro Info</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label" >Date of Birth</label>
                        <p>{data && data.dob ? data.dob : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Time of Birth</label>
                        <p>{data && data.time ? data.time + ' ' + data.ampm : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Place of Birth</label>
                        <p>{data && data.birth_place ? data.birth_place : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Nakshtra</label>
                        <p>{data && data.nakshtra ? data.nakshtra : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Manglik</label>
                        <p>{data && data.manglik ? data.manglik : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Rashi</label>
                        <p>{data && data.rashi ? data.rashi : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Religion</label>
                        <p>{data && data.religion ? data.religion : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Community</label>
                        <p>{data && data.community ? data.community : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Gothra / Gothram</label>
                        <p>{data && data.gotra ? data.gotra : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Caste</label>
                        <p>{data && data.caste ? data.caste : "-"}</p>
                    </div>
                </div>
            </div>


        </>

    );
}
