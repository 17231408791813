import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddFAQ, GetAllFAQ, DeleteFaq, UpdateInstructors } from '../api';


export const AddFaqs = createAsyncThunk('AddFaqs', async (formData, { rejectWithValue }) => {
    try {
        const response = await AddFAQ(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetFaqs = createAsyncThunk('GetFaqs', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllFAQ();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelFaq = createAsyncThunk('DelFaq', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteFaq(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

// export const EditInstructor = createAsyncThunk('EditInstructor', async (formData, { rejectWithValue }) => {
//     try {
//         const response = await UpdateInstructors(formData);
//         return response;
//     } catch (error) {
//         return rejectWithValue(error.response);
//     }
// });

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        error: null,
        success: false,
        data: null

    },
    reducers: {
        clearfq: state => {
            state.success = false;
            state.error = null;
        },

    },
    extraReducers: builder => {
        builder
            .addCase(AddFaqs.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(AddFaqs.fulfilled, (state, action) => {
                state.success = true;
                state.error = null;
            })
            .addCase(AddFaqs.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetFaqs.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetFaqs.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetFaqs.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DelFaq.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DelFaq.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DelFaq.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            // .addCase(EditInstructor.pending, state => {
            //     state.error = null;
            //     state.success = false;
            // })
            // .addCase(EditInstructor.fulfilled, (state, action) => {
            //     state.success = true;
            //     state.error = null;
            // })
            // .addCase(EditInstructor.rejected, (state, action) => {
            //     state.error = action.payload;
            //     state.success = false;
            // })

    },
});
export const { clearfq } = faqSlice.actions;
export default faqSlice.reducer;

