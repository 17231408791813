import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import Sidebar from "../../../Layouts/Sidebar";
import { API_BASE_URL } from "../../../../Reducer/api";
import { GetDetails } from "../../../../Reducer/userSlice";

const UserDetails=() =>{
 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.basic);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);




    const fetchData = useCallback(() => {
        let dts = { uid: params.id };
        dispatch(GetDetails(dts));
    }, [dispatch, params.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);



    return (
        <>
            <div className="basic_sec">

                <h3>Basic Info</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Mobile:</label><p>{data && data.mobile}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Email</label><p>{data && data.email}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Name:</label><p>{data && data.basic && data.basic.name ? data.basic.name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Gender</label><p>{data && data.basic && data.basic.gender ? data.basic.gender : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Marital Status</label><p>{data && data.basic && data.basic.Marital_status}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Mother Tongue</label><p>{data && data.basic && data.basic.Mother_tongue ? data.basic.Mother_tongue : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Languages Known</label>
                        <ul>
                            {data && data.basic && data.basic.languages && data.basic.languages.length > 0 ? data.basic.languages.map((lang, i) => (
                                <li>{lang.value}</li>
                            )) : "-"
                            }
                        </ul>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Height</label><p>{data && data.basic && data.basic.height ? data.basic.height : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Weight (in Kg)</label><p>{data && data.basic && data.basic.weight ? data.basic.weight : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Physical Status</label>
                        <p>{data && data.basic && data.basic.health ? data.basic.health : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Any Disability</label>
                        <p>{data && data.basic && data.basic.Disability ? data.basic.Disability : "-"}</p>

                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Details of Disability or other Disease</label>
                        <p>{data && data.basic && data.basic.Disease ? data.basic.Disease : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Country</label>
                        <p>{data && data.basic && data.basic.country ? data.basic.country : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">State</label>
                        <p>{data && data.basic && data.basic.state ? data.basic.state : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Current City</label>
                        <p>{data && data.basic && data.basic.city && data.basic.city ? data.basic.city : "-"}</p>
                    </div>

                </div>


            </div>
        </>

    );
}

export default React.memo(UserDetails);
