import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetFamily } from "../../../../Reducer/userSlice";


export default function FamilyDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.family);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);


    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetFamily(dts));

    }, []);


    return (
        <>

            <div className="basic_sec">

                <h3>Family Info</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Father’s Name</label>
                        <p>{data && data.father_name ? data.father_prefix + ' ' + data.father_name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Mother’s Name</label>
                        <p>{data && data.mother_name ? data.mother_prefix + ' ' + data.mother_name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Father's Occupation</label>
                        <p>{data && data.father_occcupation ? data.father_occcupation : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Mother's Occupation</label>
                        <p>{data && data.mother_occcupation ? data.mother_occcupation : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Family Cultural Values or Practices</label>
                        <p>{data && data.familyCulture ? data.familyCulture : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Family Location</label>
                        <p>{data && data.location ? data.location : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Family Status</label>
                        <p>{data && data.family_status ? data.family_status : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Total Brothers</label>
                        <p>{data && data.brother_count ? data.brother_count : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Married Brothers Count</label>
                        <p>{data && data.brother_married ? data.brother_married : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Younger Brother Count</label>
                        <p>{data && data.younger_brother ? data.younger_brother : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Elder Brother Count</label>
                        <p>{data && data.elder_brother ? data.elder_brother : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Total Sisters</label>
                        <p>{data && data.sister_count ? data.sister_count : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Married Sisters Count</label>
                        <p>{data && data.sister_married ? data.sister_married : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Younger Sisters Count</label>
                        <p>{data && data.younger_sister ? data.younger_sister : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Elder Sisters Count</label>
                        <p>{data && data.elder_sister ? data.elder_sister : "-"}</p>
                    </div>

                </div>


            </div>


        </>

    );
}
