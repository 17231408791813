import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Sidebar from "../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { DelInstructor, GetInstructor, clearResInst } from "../../../../Reducer/PagesContentSlices/instructorSlice";
import DataTable from 'react-data-table-component';
import { API_BASE_URL } from "../../../../Reducer/api";
import { DelFaq, GetFaqs, clearfq } from "../../../../Reducer/PagesContentSlices/faqSlice";
import Layout from "../../../Layouts/Layout";
import DynamicDataTable from "../../../../Utils/DynamicDataTable";

export default function All() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.faq.success);
    const error = useSelector(state => state.faq.error);
    const data = useSelector(state => state.faq.data);

    console.log('dsfdsfs', data);

    console.log('success', success);
    console.log('error', error);
    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearfq());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearfq());
            dispatch(GetFaqs());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearfq());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearResInst());
        }
    }

    const columns = [
        {
            header: 'Category',
            field: 'category',
            width : "200px",
            wrap: true

        },
        {
            header: 'Question',
            field: 'question',
            width : "200px",
            wrap: true

        },
        
        {
            header: 'Content',
            field: 'content',
            width : "500px",
            wrap: true
        },

        {
            header: 'Action',
            body: (record) => (
                <>
                    {/* <a onClick={() => edit(record)}>
                        <i className="fa fa-edit"></i>
                    </a> */}
                    
                    <a onClick={() => Del(record._id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        <Button label="delete" severity="danger" icon="pi pi-trash" />
                    </a>
                </>
            )
        }

    ];




    useEffect(() => {
        dispatch(GetFaqs());
    }, []);

    function Del(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DelFaq(data));
        }
    }



    // function edit(data) {

    //     navigate('/Pages/EditInstructors', { state: { data: data } });

    // }


    return (
        <>
        <Layout>
          <div className="grid">
            <div className="col-12">
              <div className="card">
                <h5><Link to="/Pages/AddFaqs">Add</Link></h5>
                <DynamicDataTable
                  data={data}
                  columns={columns}
                  loading={false}
                  emptyMessage="No customers found."
              />
                
              </div>
            </div>
          </div>
        </Layout>
            

        </>

    );
}
