import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {GetDashboardLIst,MaleFemaleDataCount,PaidFreeUserCount,GetUserStatusCount } from './api';

export const DashboardData = createAsyncThunk('DashboardData', async (_, { rejectWithValue }) => {
    try {
        const response = await GetDashboardLIst();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const MaleFemaleData = createAsyncThunk('MaleFemaleData', async (_, { rejectWithValue }) => {
    try {
        const response = await MaleFemaleDataCount();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const PaidFreeUser = createAsyncThunk('PaidFreeUser', async (_, { rejectWithValue }) => {
    try {
        const response = await PaidFreeUserCount();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
export const UserStatusCount = createAsyncThunk('UserStatusCount', async (_, { rejectWithValue }) => {
    try {
        const response = await GetUserStatusCount();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        error: null,
        success: false,
        data: null,
        malefemale:null,
        PaidFreeUserdata:null,
        UserStatusCountdata:null

    },
    reducers: {
    

    },
    extraReducers: builder => {
        builder
            .addCase(DashboardData.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DashboardData.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DashboardData.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(MaleFemaleData.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(MaleFemaleData.fulfilled, (state, action) => {
                state.malefemale = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(MaleFemaleData.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            }).addCase(PaidFreeUser.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(PaidFreeUser.fulfilled, (state, action) => {
                state.PaidFreeUserdata = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(PaidFreeUser.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(UserStatusCount.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(UserStatusCount.fulfilled, (state, action) => {
                state.UserStatusCountdata = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UserStatusCount.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })


    },
});
export const { } = dashboardSlice.actions;
export default dashboardSlice.reducer;

