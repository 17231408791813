import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import Sidebar from "../../Layouts/Sidebar";
import { changePassword,clearStatus } from "../../../Reducer/authSlice";
import Layout from "../../Layouts/Layout";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
export default function ChangePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
    });
    
    const success = useSelector(state => state.auth.success);
    const error = useSelector(state => state.auth.error);
   
    if (success && success.data.status == "Success") {
        Swal.fire({
            title: 'Congratulation!',
            text: success.data.message,
            icon: 'success'
        });
        dispatch(clearStatus());
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
               
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                

        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        let form = new FormData();
        form.append('password', formData.password);
       
        dispatch(changePassword(form));
    }
    function handleChange(e) {
        
        setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        

    }

    useEffect(() => {
    }, []);

    return (
        <>
        <Layout>

        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Password</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Enter new Password</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      name="password"
                      required onChange={(e) => handleChange(e)}
                    />
                    
                 
                  </div>
                 
                  
                  
                
                     

                     
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


       
        </Layout>
          

        </>

    );
}
