import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddHomePage, GetHomePage, AddSliderData, AllSidesData, DeleteSlide, AddTranscendsData, AllTranscends, UpdateTranscendsData, DeleteTranscends } from '../api';

export const AddHome = createAsyncThunk('add home', async (formData, { rejectWithValue }) => {
    try {
      const response = await AddHomePage(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const GetHome = createAsyncThunk('get omeeee', async (formData, { rejectWithValue }) => {
    try {
      const response = await GetHomePage(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const AddSlider = createAsyncThunk('get AddSlider', async (formData, { rejectWithValue }) => {
    try {
      const response = await AddSliderData(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const AllSlides = createAsyncThunk('get all slidsss', async (_, { rejectWithValue }) => {
    try {
      const response = await AllSidesData();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const DelSlide = createAsyncThunk('get all slide deell', async (formData, { rejectWithValue }) => {
    try {
      const response = await DeleteSlide(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const AddTranscends = createAsyncThunk('get Transcends', async (formData, { rejectWithValue }) => {
    try {
      const response = await AddTranscendsData(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  
  export const AllTrans = createAsyncThunk('get all AllTrans', async (_, { rejectWithValue }) => {
    try {
      const response = await AllTranscends();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const UpdateTranscends = createAsyncThunk('get UpdateTranscends', async (formData, { rejectWithValue }) => {
    try {
      const response = await UpdateTranscendsData(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  
  export const DelTranscends = createAsyncThunk('get DelTranscends', async (formData, { rejectWithValue }) => {
    try {
      const response = await DeleteTranscends(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });



const homeSlice = createSlice({
  name: 'home',
  initialState: {
    error: null,
    success: false,
    data : [],
    home : null
 

  },
  reducers: {
    clearStatusHome: state => {
      state.success = false;
      state.error = null;
    },

  },
  extraReducers: builder => {
    builder
      .addCase(AddHome.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(AddHome.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AddHome.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(GetHome.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetHome.fulfilled, (state, action) => {
        state.home = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetHome.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(AddSlider.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(AddSlider.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
      })
      .addCase(AddSlider.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(AllSlides.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(AllSlides.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AllSlides.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(DelSlide.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(DelSlide.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(DelSlide.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(AddTranscends.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(AddTranscends.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
      })
      .addCase(AddTranscends.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(AllTrans.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(AllTrans.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(AllTrans.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })     
      .addCase(UpdateTranscends.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(UpdateTranscends.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
      })
      .addCase(UpdateTranscends.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(DelTranscends.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(DelTranscends.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(DelTranscends.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })   

      
  },
});
export const { clearStatusHome } = homeSlice.actions;
export default homeSlice.reducer;

