import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";

import { InputTextarea } from 'primereact/inputtextarea';
        
import { Button } from "primereact/button";
import Sidebar from "../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../../../Reducer/api";
import { EditInstructor, clearResInst } from "../../../../Reducer/PagesContentSlices/instructorSlice";
import Layout from "../../../Layouts/Layout";

export default function Edit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state.data;
    const [inputField, setInputField] = useState(stateData);
    const success = useSelector(state => state.instructor.success);
    const error = useSelector(state => state.instructor.error);
    const [file, setFile] = useState();
 

    useEffect(() => {

    }, []);


    const inputsHandler = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
    }

    function handleChange(e) {
        setInputField({
            ...inputField,
            ['image']: e.target.files[0]
        });
        setFile(URL.createObjectURL(e.target.files[0]));
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            let formData = new FormData();
            formData.append('image', inputField.image);
            formData.append('name', inputField.name);
            formData.append('Designation', inputField.Designation);
            formData.append('bio', inputField.bio);
            formData.append('id', inputField._id);
            dispatch(EditInstructor(formData));
        } catch (e) {

            console.error('Error during login:', e);

        };
    }

    if (success && success == true) {
        Swal.fire({
            title: 'Congratulation!',
            text: 'Updated Successfully',
            icon: 'success'
        });
        dispatch(clearResInst());
        setTimeout(() => {
            navigate('/Pages/Instructors');
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearResInst());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearResInst());
        }
    }

    return (
        <>
         <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add Instructor</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Image</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      accept="image/*" name="image" required onChange={(e) => handleChange(e)}
                    />
                    {file ? <img class="w-100" height="150px" src={file} /> : <img class="w-100" height="150px" src={API_BASE_URL + inputField.image} />}
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Name</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      onChange={inputsHandler} name="name" required
                      defaultValue={inputField.name}
                    />
                 
                  </div>
                  
                  
                  <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Designation</label>
                        <InputText
                          type="text"
                          onChange={inputsHandler} name="Designation" required
                          defaultValue={inputField.Designation}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Bio</label>
                    <InputTextarea
                      id="lastname2"
                      type="text"
                      onChange={inputsHandler} name="bio" 
                      defaultValue={inputField.bio}
                    />
                  </div>

                     
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </Layout>
            

        </>

    );
}
