import react,{useEffect,useRef} from "react";
import { Toast } from 'primereact/toast';
        

const ToastComp =()=>{
    const toast = useRef(null);
    
    useEffect(() => {
        showSuccess({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Message Detail',
            life: 3000
        });
    }, []); // Empty dependency array means this effect runs only once after the component mounts

    const showSuccess = (props) => {
        toast.current?.show({
            severity: props.severity,
            summary:  props.summary,
            detail: props.detail,
            life: props.life
        });
    };
    return (
        <>
        <Toast ref={toast} />
       
        </>
        )

}

export default ToastComp;


