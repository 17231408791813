import * as XLSX from "xlsx";
export const  exportToExcel = (data, fileName) => {
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };