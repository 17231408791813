import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Sidebar from "../../../Layouts/Sidebar";
import {
  AddKootasLi,
  DeleteGunss,
  GetkundaliContent,
  UpdateKnPage,
  clearKnRes,
} from "../../../../Reducer/PagesContentSlices/kunaliMatchingSlice";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { API_BASE_URL } from "../../../../Reducer/api";
import Layout from "../../../Layouts/Layout";
import DynamicDataTable from "../../../../Utils/DynamicDataTable";

export default function KundliMatchingPage() {
  const dispatch = useDispatch();
  const [kotta, setKotta] = useState({
    name: "",
    description: "",
  });
  const success = useSelector((state) => state.kundaliPg.success);
  const error = useSelector((state) => state.kundaliPg.error);
  const [data, setData] = useState([]);
  const [data2, setPageData] = useState({});
  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let dts = { id: "Kundali" };
    let res = await dispatch(GetkundaliContent(dts));
    console.log("resss", res);
    if (res.payload && res.payload.data.data) {
      setData(res.payload.data.data.guns);
      setPageData(res.payload.data.data.pageData);
    }
  }

  const columns = [
    {
      header: "Name",
      field: "name",
    },
    {
      header: "Description",
      field: "description",
    },
    {
      header: "Action",
      body: (record) => (
        <>
          {/* <Link to={`/Users/${record._id}`}>
                        <i className="fa fa-eye"></i>
                    </Link> */}

          <a onClick={() => del(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
        </>
      ),
    },
  ];

  function del(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let dat = { id: id };
      dispatch(DeleteGunss(dat));
    }
  }

  const handleChnage = (e) => {
    setKotta({
      ...kotta,
      [e.target.name]: e.target.value,
    });
  };

  const AddKoota = (e) => {
    e.preventDefault();
    if (kotta.name !== "" && kotta.description !== "") {
      console.log("bjabf", kotta.name, kotta.description);
      let formData = new FormData();
      formData.append("name", kotta.name);
      formData.append("description", kotta.description);
      dispatch(AddKootasLi(formData));
    } else {
      alert("please fill all details!");
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "kundali_Page_img") {
      setPageData({
        ...data2,
        [e.target.name]: e.target.files[0],
      });
      setFile(URL.createObjectURL(e.target.files[0]));
    } else {
      setPageData({
        ...data2,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onSubmitPage = (e) => {
    e.preventDefault();

    console.log("data2", data2);
    let formData = new FormData();
    formData.append("kundali_Page_img", data2.kundali_Page_img);
    formData.append("kundali_Page_desc", data2.kundali_Page_desc);
    formData.append("kundali_Page_imp_title", data2.kundali_Page_imp_title);
    formData.append("kundali_Page_imp_desc", data2.kundali_Page_imp_desc);
    formData.append("kundali_Page_imp_h1", data2.kundali_Page_imp_h1);
    formData.append("kundali_Page_imp_h2", data2.kundali_Page_imp_h2);
    formData.append("kundali_Page_imp_h3", data2.kundali_Page_imp_h3);
    formData.append("kundali_Page_imp_cont1", data2.kundali_Page_imp_cont1);
    formData.append("kundali_Page_imp_cont2", data2.kundali_Page_imp_cont2);
    formData.append("kundali_Page_imp_cont3", data2.kundali_Page_imp_cont3);
    formData.append("understanding_content", data2.understanding_content);
    formData.append("pageName", "Kundali");
    dispatch(UpdateKnPage(formData));
  };

  if (success && success.status == 200) {
    if (success.data.data) {
      dispatch(clearKnRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      setKotta({
        ...kotta,
        name: "",
        description: "",
      });
      dispatch(clearKnRes());
      getData();
    }
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearKnRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearKnRes());
    }
  }

  return (
    <>
      <Layout>
        <Dialog
          header="Add Kootas"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="grid">
            <div className="col-12">
              <div className="card">
                <form onSubmit={AddKoota}>
                  <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                      <label htmlFor="firstname2">Name</label>
                      <InputText
                        id="firstname2"
                        type="text"
                        onChange={handleChnage}
                        value={kotta.name}
                        name="name"
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <label htmlFor="lastname2">Description</label>
                      <InputText
                        type="text"
                        name="description"
                        onChange={handleChnage}
                        value={kotta.description}
                      />
                    </div>

                    <div class="col-12">
                      <div className="md:col-3">
                        <Button
                          label="Submit"
                          type="submit"
                          severity="Primary"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Dialog>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Kundali Matching Page</h5>

              <form onSubmit={onSubmitPage}>
                <div className="p-fluid formgrid grid">
                        <div className="field col-12">
                        <h2>Introduction to Kundali Matching</h2>  
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firstname2">Image</label>
                            <InputText
                            id="firstname2"
                            type="file"
                            name="kundali_Page_img"
                            accept="image/*"
                            onChange={(e) => handleChange(e)}
                            />
                            {file ? (
                            <img src={file} />
                            ) : (
                            <img
                                src={
                                data2 &&
                                data2.kundali_Page_img &&
                                API_BASE_URL + data2.kundali_Page_img
                                }
                                width={400} height={400}
                            />
                            )}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="lastname2">Content</label>
                            <InputTextarea
                            id="lastname2"
                            type="text"
                            value={data2 && data2.kundali_Page_desc}
                                name="kundali_Page_desc"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    
                    <div className="field col-12">
                   <h2>Importance of Kundali matching</h2>
                   </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Title</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      value={data2 && data2.kundali_Page_imp_title}
                        name="kundali_Page_imp_title"
                        onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Description</label>
                    <InputTextarea
                      id="firstname2"
                      type="text"
                      value={data2 && data2.kundali_Page_imp_desc}
                        name="kundali_Page_imp_desc"
                        onChange={(e) => handleChange(e)}
                    />
                  </div>
                   
                 

                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Heading 1</label>
                    <InputText
                      type="text"
                      value={data2 && data2.kundali_Page_imp_h1}
                      name="kundali_Page_imp_h1"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Content 1</label>
                    <InputTextarea
                      type="text"
                      value={data2 && data2.kundali_Page_imp_cont1}
                      name="kundali_Page_imp_cont1"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Heading 2</label>
                    <InputText
                      type="text"
                      value={data2 && data2.kundali_Page_imp_h2}
                      name="kundali_Page_imp_h2"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Content 2</label>
                    <InputTextarea
                      type="text"
                      value={data2 && data2.kundali_Page_imp_cont2}
                      name="kundali_Page_imp_cont2"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Heading 3</label>
                    <InputText
                      type="text"
                      value={data2 && data2.kundali_Page_imp_h3}
                      name="kundali_Page_imp_h3"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Content 3</label>
                    <InputTextarea
                      type="text"
                      value={data2 && data2.kundali_Page_imp_cont3}
                      name="kundali_Page_imp_cont3"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="field col-12">
                  <h2>Understanding the Kundali Matching</h2>
                  </div>
                 
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Content 3</label>
                    <InputTextarea
                      type="text"
                      value={data2 && data2.understanding_content}
                      name="understanding_content"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h2>
                Gun List{" "}
                <Button
                  label="Add"
                  onClick={() => setVisible(true)}
                  severity="Primary"
                />
              </h2>
              <DynamicDataTable
                data={data}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
              />
            </div>
          </div>
        </div>
      </Layout>

      
    </>
  );
}
