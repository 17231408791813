import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../Layouts/Sidebar";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import Swal from 'sweetalert2';
import { DelCoupon, GetCoupon, clearCop } from "../../../Reducer/couponSlice";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";
import { Button } from 'primereact/button';
export default function Coupons() {
    const dispatch = useDispatch();
    const data = useSelector(state => state.cop.data);
    const success = useSelector(state => state.cop.success);
    const error = useSelector(state => state.cop.error);


    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearCop());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearCop());
            dispatch(GetCoupon());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearCop());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearCop());
        }
    }

    const columns = [
        {
            header: "Coupon Type",
            field: "coupon_type",
            wrap: true
        },
        {
            header: "Code",
            field: "code",
            wrap: true
        },
        {
            header: 'Discount Percentage',
            field: 'percentage',
            wrap: true
        },
        {
            header: 'Coupon Start Date',
            field: 'start_date',
            wrap: true,
            body: (record) => {
                // Assuming start_date is a valid date string
                const startDate = new Date(record.start_date);
                return startDate.toLocaleDateString('en-US');
            }
        },
        {
            header: 'Coupon Expiry Date',
            field: 'end_date',
            wrap: true,
            body: (record) => {
                // Assuming end_date is a valid date string
                const endDate = new Date(record.end_date);
                return endDate.toLocaleDateString('en-US');
            }
        },
        {
            header: 'Action',
            button: true,
            width: "225px",


            body: (record) => {
                return (<>
                  <a onClick={() => DelRecord(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
                   </>
                )

            }
        },

    ];

    function DelRecord(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DelCoupon(data));
        }
    }

    useEffect(() => {

        dispatch(GetCoupon());
    }, []);


    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
            <h2>Coupons<span><Link to="/Coupons/AddCoupon">Add</Link></span></h2>
              <DynamicDataTable
                data={data}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
              />
            </div>
          </div>
        </div>
        </Layout>
            

        </>

    );
}
