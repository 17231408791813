import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetCarrer } from "../../../../Reducer/userSlice";


export default function CarrerDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.carrer);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);


    console.log('aaaaa', data);


    useEffect(() => {
        let dts = { uid: params.id };
        dispatch(GetCarrer(dts));

    }, []);



    return (
        <>

            <div className="basic_sec">

                <h3>Carrer Info</h3>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Organization</label><p>{data && data.company_name ? data.company_name : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Occupation</label><p>{data && data.occupation ? data.occupation : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Annual Income</label><p>{data && data.income ? data.income : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Company Type</label><p>{data && data.company_type ? data.company_type : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Job Location</label><p>{data && data.job_location ? data.job_location : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">How long you are working with this company?</label><p>{data && data.working_period ? data.working_period : "-"}</p>
                    </div>


                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Highest Education Qualification</label><p>{data && data.Ed_qalification ? data.Ed_qalification : "-"}</p>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Educational Institution</label><p>{data && data.Ed_institution ? data.Ed_institution : "-"}</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <label class="form-label">Aditional Educational Qulaification</label>
                        <p>{data && data.Ed_other_qualification ? data.Ed_other_qualification : "-"}</p>
                    </div>


                </div>


            </div>


        </>

    );
}
