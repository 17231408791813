import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LoginAdmin, clearStatus } from "../../../Reducer/authSlice";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import {PROJECT_NAME} from "../../../Constant/constants";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const success = useSelector(state => state.auth.success);
  const error = useSelector(state => state.auth.error);
  const Login = useSelector(state => state.auth.auth);
  const isAuthenticated = useSelector(state => state.auth.logincheck);
  

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/Dashboard');
    }
  }, [isAuthenticated, navigate]);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(LoginAdmin(formData));

    } catch (e) {
      console.error('Error during login:', error);
    }
  };

  if (success) {
    Swal.fire({
      title: 'Congratulation!',
      text: 'Login successfully',
      icon: 'success'
    });
    dispatch(clearStatus());

    setTimeout(() => {
      navigate('/Dashboard');
    }, 1000);
  }


  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: 'Error!',
          text: error.data.message,
          icon: 'error'
        });
        dispatch(clearStatus());
        break;
      default:
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong',
          icon: 'error'
        });
        dispatch(clearStatus());
        break;
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden', { 'p-input-filled': "outlined" === 'filled' });

  return (
    <div className={containerClassName}>
      
            <div className="flex flex-column align-items-center justify-content-center">
               
                <div
                    style={{
                        borderRadius: '56px',
                        padding: '0.3rem',
                        background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)',
                        width:"87%"
                    }}
                >
                  <form className="loginForm" onSubmit={handleSubmit}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                        <img src={`images/logo.png`} alt={PROJECT_NAME} height="50" className="mb-3" />
                            <div className="text-900 text-3xl font-medium mb-3">Welcome</div>
                            <span className="text-600 font-medium">Sign in to continue</span>
                        </div>

                        <div>
                            <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                Email
                            </label>
                            <InputText id="email1" type="email" name="email" placeholder="Email address"  value={formData.email} onChange={handleChange} className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                            <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
                                Password
                            </label>
                            <Password inputId="password1" type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" toggleMask className="w-full mb-5" inputClassName="w-full p-3 md:w-30rem"></Password>

                            {/* <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                <div className="flex align-items-center">
                                    <Checkbox inputId="rememberme1" checked={checked} onChange={(e) => setChecked(e.checked ?? false)} className="mr-2"></Checkbox>
                                    <label htmlFor="rememberme1">Remember me</label>
                                </div>
                                <a className="font-medium no-underline ml-2 text-right cursor-pointer" style={{ color: 'var(--primary-color)' }}>
                                    Forgot password?
                                </a>
                            </div> */}
                            <Button label="Sign In" className="w-full p-3 text-xl" type="submit"></Button>
                        </div>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    // <div className="login-container">
    //   <div class="card">
    //     <div class="app-brand">
    //       <img src="images/favicon.png" alt="logo" />
    //     </div>
    //     <h4>Welcome to Vedic Marriage!👋</h4>
    //     <form className="loginForm" onSubmit={handleSubmit}>
    //       <div className="form-group">
    //         <label className="form-label">Email</label>
    //         <input className="form-control"
    //           type="email"
    //           name="email"
    //           value={formData.email}
    //           onChange={handleChange}
    //         />
    //       </div>
    //       <div className="form-group">
    //         <label className="form-label">Password</label>
    //         <input className="form-control"
    //           type="password"
    //           name="password"
    //           value={formData.password}
    //           onChange={handleChange}
    //           required
    //         />
    //       </div>
    //       <button type="submit">Login</button>
    //     </form>
    //   </div>
    // </div>
  );
}
