import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Sidebar from "../../../../../Layouts/Sidebar";
import { AllTrans, DelTranscends, clearStatusHome } from "../../../../../../Reducer/PagesContentSlices/homeSlice";
import { API_BASE_URL } from "../../../../../../Reducer/api";
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import Layout from "../../../../../Layouts/Layout";
import DynamicDataTable from "../../../../../../Utils/DynamicDataTable";

export default function All() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.home.success);
    const error = useSelector(state => state.home.error);
    const data = useSelector(state => state.home.data);

    console.log('success', success);
    console.log('error', error);
    if (success && success.status == 200) {
        if (success.data.data) {
            dispatch(clearStatusHome());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearStatusHome());
            dispatch(AllTrans());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatusHome());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearStatusHome());
        }
    }

    const columns = [
        {
            header: 'Title',
            field: 'title',
            width: "200px",
            wrap: true
        },
        {
            header: 'Description',
            field: 'content',
            width: "400px",
            wrap: true

        },
        {
            header: 'Image',
            field: 'image',
            width: "200px",
            body: (record) => (
                <img src={API_BASE_URL + record.image} alt="Profile" style={{ width: '100px', height: 'auto' }} />

            )

        },


        {
            header: 'Action',
            button: true,
            width: "225px",


            body: (record) =>( 
                <>
                    

                    <a onClick={() => edit(record)}>
                    
                    <Button label="edit" severity="info" icon="pi pi-pencil" />
                            </a>
                    <a onClick={() => Del(record._id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        <Button label="delete" severity="danger" icon="pi pi-trash" />
                    </a>
                </>


              

            )
        },
    ];



    useEffect(() => {
        dispatch(AllTrans());
    }, []);

    function Del(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DelTranscends(data));
        }
    }



    function edit(data) {

        navigate('/Pages/EditTranscends', { state: { data: data } });

    }


    return (
        <>
        <Layout>
          <div className="grid">
            <div className="col-12">
              <div className="card">
                <h5><Link to="/Pages/AddTranscends"><i class="bi bi-plus"></i>Add</Link></h5>
                <DynamicDataTable
                  data={data}
                  columns={columns}
                  loading={false}
                  emptyMessage="No customers found."
              />
                
              </div>
            </div>
          </div>
        </Layout>
       
      </>

    );
}
