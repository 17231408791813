import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";

import { Editor } from 'primereact/editor';
        
import { InputTextarea } from 'primereact/inputtextarea';
        
import { Button } from "primereact/button";

import Sidebar from "../../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { AddResouces, clearRes }  from "../../../../../Reducer/PagesContentSlices/pagesSlice";
import Layout from "../../../../Layouts/Layout";

export default function AddResourcesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.pages.successAll);
    const error = useSelector(state => state.pages.errorAll);
    const [text, setText] = useState('');
    const [inputField, setInputField] = useState({
        title: '',
        description: '',
        meta_tag: '',
        meta_description: '',
        slug: '',
        image: null,
        pdf: null,
        url:''
    });
    const [file, setFile] = useState();
    const [fileData, setData] = useState();

    useEffect(() => {
      
    }, [success,error]);

    const toSeoFriendlyUrl=(text) =>{
      // Convert text to lowercase
      let seoUrl = text.toLowerCase();
      
      // Remove special characters and punctuation marks
      seoUrl = seoUrl.replace(/[^\w\s-]/g, '');
    
      // Replace spaces with hyphens
      seoUrl = seoUrl.replace(/\s+/g, '-');
    
      return seoUrl;
    }
    const inputsHandler = (e) => {
      
      if("htmlValue" in e){
        setInputField({
          ...inputField,
          ["description"]: e.htmlValue
        });
      }else{
        if(e.target.name==="title"){
          setInputField({
            ...inputField,
            ["url"]:  toSeoFriendlyUrl(e.target.value),
            [e.target.name]: e.target.value

          });
        }else if(e.target.name==="url"){
          setInputField({
            ...inputField,
          
            [e.target.name]: toSeoFriendlyUrl(e.target.value)

          });
        }        
        else{
          setInputField({
            ...inputField,
            [e.target.name]: e.target.value
          });
        }
        
      }
      
      
        
    }

    function handleChange(e) {
        setInputField({
            ...inputField,
            ['image']: e.target.files[0]
        });
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    function addPDF(e) {
        setInputField({
            ...inputField,
            ['pdf']: e.target.files[0]
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            console.log('inputField', inputField);
            let formData = new FormData();
            formData.append('title', inputField.title);
            formData.append('description', inputField.description);
            formData.append('url', inputField.url);
            formData.append('meta_tag', inputField.meta_tag);
            formData.append('meta_description', inputField.meta_description);
            formData.append('slug', inputField.slug);
            formData.append('image', inputField.image);
            formData.append('pdf', inputField.pdf);
           dispatch(AddResouces(formData));
        } catch (e) {
          
            console.error('Error during login:', e);

        };
    }

    if (success && success == true) {
        Swal.fire({
            title: 'Congratulation!',
            text: 'Resources Added Successfully',
            icon: 'success'
        });
        dispatch(clearRes());
        setTimeout(() => {
              navigate('/Pages/Resources');
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
          case 403:
            Swal.fire({
              title: 'Error!',
              text: error.data.message,
              icon: 'error'
            });
            dispatch(clearRes());
            break;
          default:
            Swal.fire({
              title: 'Error!',
              text: 'Something went wrong',
              icon: 'error'
            });
            dispatch(clearRes());
        }
      }

    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add Resource</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Image</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      accept="image/*" name="image" required onChange={(e) => handleChange(e)}
                    />
                     {file && <img class="w-100" id="blah" src={file} />}
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Title</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      onChange={inputsHandler} name="title" required 
                    />
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Url</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      onChange={inputsHandler} name="url" required 
                      value={inputField.url}
                    />
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Description</label>
                    <InputTextarea
                      id="lastname2"
                      type="text"
                      autoResize 
                      value={inputField.description}
                      name="description" onChange={inputsHandler} required
                    />
                    
                     {/* <Editor value={inputField.description} onTextChange={inputsHandler} style={{ height: '320px' }} name="description" /> */}
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Upload PDF</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      accept="application/pdf" name="pdf" onChange={(e) => addPDF(e)}
                    />
                     {file && <img class="w-100" id="blah" src={file} />}
                 
                  </div>
                  <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Tag</label>
                        <InputText
                          type="text"
                          onChange={inputsHandler} name="meta_tag" required
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Description</label>
                        <InputTextarea
                          type="text"
                          onChange={inputsHandler} name="meta_description" required
                        />
                      </div>

                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Slug</label>
                        <InputText
                          type="text"
                          onChange={inputsHandler} name="slug" required
                        />
                      </div>
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </Layout>
           

        </>

    );
}
