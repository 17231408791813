import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddSubscriptionPlan, GetAllSubscriptionPlan, DeleteSubscriptionPlan, UpdateSubscriptionPlan } from './api';

export const SavePlan = createAsyncThunk('SavePlan', async (formData, { rejectWithValue }) => {
    try {
        const response = await AddSubscriptionPlan(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetPlans = createAsyncThunk('GetPlans', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllSubscriptionPlan();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelPlan = createAsyncThunk('DelPlan', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteSubscriptionPlan(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdatePlan = createAsyncThunk('UpdatePlan', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateSubscriptionPlan(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const SubscriptionSlice = createSlice({
    name: 'plan',
    initialState: {
        error: null,
        success: false,
        data: []
    },
    reducers: {
        clearPlan: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(SavePlan.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(SavePlan.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SavePlan.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetPlans.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetPlans.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetPlans.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DelPlan.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DelPlan.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DelPlan.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(UpdatePlan.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(UpdatePlan.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdatePlan.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
    },
});
export const { clearPlan } = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;

