import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';



import { Button } from 'primereact/button';


import 'react-data-table-component-extensions/dist/index.css';
import { DelQuery, GetAllQueries, clearQueryRes } from "../../../Reducer/querySlice";
import Swal from 'sweetalert2';
import { DeleteQuery } from "../../../Reducer/api";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";
export default function ContactQuery() {
    const dispatch = useDispatch();
   
    const data = useSelector(state => state.query.data);
    const success = useSelector(state => state.query.success);
    const error = useSelector(state => state.query.error);
    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearQueryRes());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearQueryRes());
            dispatch(GetAllQueries());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearQueryRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearQueryRes());
        }
    }

    const columns = [
        {
            header: "Name",
            field: "name",
            wrap: true
        },
        {
            header: "Email",
            field: "email",
            wrap: true
        },
        {
            header: 'Phone',
            field: 'number',
            wrap: true
        },
        {
            header: 'Subject',
            field: 'subject',
            wrap: true
        },
        {
            header: 'Description',
            field: 'message',
            width: "400px",
            wrap: true,
            body: (record) => {
                const maxLength = 100; // Maximum length of description
                const truncatedDescription = record?.message?.length > maxLength ?
                    record?.message.substring(0, maxLength) + '...' :
                    record?.message;
                return <span>{truncatedDescription}</span>;
            }
        },
        {
            header: 'Action',
            
            width: "225px",


            body: (record) => (
                
                <a onClick={() => DelRecord(record._id)}>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    <Button label="delete" severity="danger" icon="pi pi-trash" />
                </a>
            )
            
        },

    ];

    function DelRecord(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DelQuery(data));
        }
    }

    useEffect(() => {

        dispatch(GetAllQueries());
    }, []);


    return (
        <>
            <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
             
              <DynamicDataTable
                data={data}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
            />
              
            </div>
          </div>
        </div>
      </Layout>

        </>

    );
}
