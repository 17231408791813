import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";

import { InputTextarea } from 'primereact/inputtextarea';
        
import { Button } from "primereact/button";

import Sidebar from "../../../../Layouts/Sidebar";
import Swal from 'sweetalert2';
import { AddStory, clearStRes } from "../../../../../Reducer/PagesContentSlices/storySlice";
import Layout from "../../../../Layouts/Layout";

export default function AddStories() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.story.success);
    const error = useSelector(state => state.story.error);
    const [inputField, setInputField] = useState({
        text: '',
        image: null,
        name: '',
        designation: '',
    });
    const [file, setFile] = useState();

    useEffect(() => {

    }, [success, error]);


    const inputsHandler = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value
        });
    }

    function handleChange(e) {
        setInputField({
            ...inputField,
            ['image']: e.target.files[0]
        });
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            console.log('inputField', inputField);
            let formData = new FormData();
            formData.append('text', inputField.text);
            formData.append('name', inputField.name);
            formData.append('image', inputField.image);
            formData.append('designation', inputField.designation);
            dispatch(AddStory(formData));
        } catch (e) {

            console.error('Error during login:', e);

        };
    }

    if (success && success == true) {
        Swal.fire({
            title: 'Congratulation!',
            text: 'Stories Added Successfully',
            icon: 'success'
        });
        dispatch(clearStRes());
        setTimeout(() => {
            navigate('/Pages/Stories');
        }, 2000);
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearStRes());
        }
    }

    return (
        <>
        <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add Success Stories</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Image</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      accept="image/*" name="image" required onChange={(e) => handleChange(e)}
                    />
                      {file && <img class="w-100" height="150px" src={file} />}
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Name</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      onChange={inputsHandler} name="name" required
                    />
                 
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Text</label>
                    <InputTextarea
                      id="lastname2"
                      type="text"
                      name="text" onChange={inputsHandler} required
                    />
                  </div>
                  
                  <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Designation</label>
                        <InputText
                          type="text"
                          onChange={inputsHandler} name="designation" required
                        />
                      </div>
                      

                     
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </Layout>
            

        </>

    );
}
