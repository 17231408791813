import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddResources, getAllResource, DelResource, editResource } from '../api';

export const AddResouces = createAsyncThunk('add resources', async (formData, { rejectWithValue }) => {
  try {
    const response = await AddResources(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetResources = createAsyncThunk('get resources', async (_, { rejectWithValue }) => {
  try {
    const response = await getAllResource();
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const DeleteResource = createAsyncThunk('del resource', async (formData, { rejectWithValue }) => {
  try {
    const response = await DelResource(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const EditResouces = createAsyncThunk('editt resources', async (formData, { rejectWithValue }) => {
  try {
    const response = await editResource(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    error: null,
    success: false,
    errorAll : null,
    successAll : null,
   resources : null

  },
  reducers: {
    clearRes: state => {
      state.success = false;
      state.error = null;
      state.successAll = false;
      state.errorAll = null;
    },

  },
  extraReducers: builder => {
    builder
      .addCase(AddResouces.pending, state => {
        state.errorAll = null;
        state.successAll = false;
      })
      .addCase(AddResouces.fulfilled, (state, action) => {
        state.successAll = true;
        state.errorAll = null;
      })
      .addCase(AddResouces.rejected, (state, action) => {
        state.errorAll = action.payload;
        state.successAll = false;
      })
      .addCase(GetResources.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(GetResources.fulfilled, (state, action) => {
        state.resources = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetResources.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(DeleteResource.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(DeleteResource.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(DeleteResource.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
      .addCase(EditResouces.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(EditResouces.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
      })
      .addCase(EditResouces.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
  },
});
export const { clearRes } = pagesSlice.actions;
export default pagesSlice.reducer;

