import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import Sidebar from "../../../../Layouts/Sidebar";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { API_BASE_URL } from "../../../../../Reducer/api";
import {
  DelStory,
  GetStories,
  clearStRes,
} from "../../../../../Reducer/PagesContentSlices/storySlice";
import Layout from "../../../../Layouts/Layout";
import DynamicDataTable from "../../../../../Utils/DynamicDataTable";
export default function AllStories() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const success = useSelector((state) => state.story.success);
  const error = useSelector((state) => state.story.error);
  const storiess = useSelector((state) => state.story.data);

  if (success && success.status == 200) {
    if (success.data) {
      dispatch(clearStRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearStRes());
      dispatch(GetStories());
    }
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearStRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearStRes());
    }
  }

  useEffect(() => {
    dispatch(GetStories());
  }, []);

  const columns = [
    {
      header: "Name",
      field: "name",
      width: "100px",
    },
    {
      header: "Designation",
      field: "designation",
      width: "200px",
    },
    {
      header: "Description",
      field: "text",
      width: "300px",
      wrap: true,
    },
    {
      header: "Image",
      field: "image",
      width: "200px",
      body: (record) => (
        <img
          src={API_BASE_URL + record.image}
          alt="Profile"
          style={{ width: "100px", height: "auto" }}
        />
      ),
    },

    {
      header: "Action",
      button: true,
      width: "200px",

      body: (record) => {
        return (
          <>
           

            <a onClick={() => edit(record)}>
                    
                    <Button label="edit" severity="info" icon="pi pi-pencil" />
                </a>
          <a onClick={() => Del(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
          </>
        );
      },
    },
  ];

  function Del(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
      dispatch(DelStory(data));
    }
  }

  function edit(sttt) {
    navigate("/Pages/EditStories", { state: { data: sttt } });
  }

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>
                <Link to="/Pages/AddStories">Add</Link>
              </h5>
              <DynamicDataTable
                data={storiess}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
