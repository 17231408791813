import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Swal from 'sweetalert2';
import Sidebar from "../../../../Layouts/Sidebar";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { DeleteResource, GetResources, clearRes } from "../../../../../Reducer/PagesContentSlices/pagesSlice";
import { API_BASE_URL } from "../../../../../Reducer/api";
import Layout from "../../../../Layouts/Layout";
import DynamicDataTable from "../../../../../Utils/DynamicDataTable";
export default function AllResources() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const success = useSelector(state => state.pages.success);
    const error = useSelector(state => state.pages.error);
    const resources = useSelector(state => state.pages.resources);

    console.log('successss', success);

    if (success && success.status == 200) {
        if (success.data.data) {
            dispatch(clearRes());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearRes());
            dispatch(GetResources());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearRes());
        }
    }

    useEffect(() => {
        dispatch(GetResources());
    }, []);

    const columns = [
        {
            header: 'Title',
            field: 'title',
            width: "200px",
            wrap: true
        },
        {
            header: 'Description',
            field: 'description',
            width: "400px",
            wrap: true,
            body: (record) => {
                const maxLength = 100; // Maximum length of description
                const truncatedDescription = record.description.length > maxLength ?
                    record.description.substring(0, maxLength) + '...' :
                    record.description;
                return <span>{truncatedDescription}</span>;
            }
        },
        {
            header: 'Image',
            field: 'image',
            width: "200px",
            body: (record) => (
                <img src={API_BASE_URL + record.image} alt="Profile" style={{ width: '100px', height: 'auto' }} />

            )

        },
        {
            header: 'File',
            field: 'pdf',
            width: "100px",
            body: (record) => (
                <a href={API_BASE_URL + record.pdf} target="_blank" Download>View</a>

            )
        },

        {
            header: 'Action',
            
            width: "225px",


            body: (record) => {
                return (<>    


                    <a onClick={() => edit(record)}>
                    
                    <Button label="edit" severity="info" icon="pi pi-pencil" />
                </a>
          <a onClick={() => DelResorce(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
                    
                    </>
                )

            }
        },
    ];

    function DelResorce(id) {
        if (window.confirm("Are you sure you want to delete?")) {
            let data = { id: id };
            dispatch(DeleteResource(data));
        }
    }
    function edit(resources) {
        navigate('/Pages/EditResource', { state: { data: resources } });

    }

    return (
        <>
        <Layout>
          <div className="grid">
            <div className="col-12">
              <div className="card">
                <h5><Link to="/Pages/AddResources">Add</Link></h5>
                <DynamicDataTable
                  data={resources}
                  columns={columns}
                  loading={false}
                  emptyMessage="No customers found."
              />
                
              </div>
            </div>
          </div>
        </Layout>
       
      </>
    

    );
}
