import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateAboutUsPage, GetAboutPage } from '../api';

export const UpdateAboutUs = createAsyncThunk('UpdateAboutUs', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateAboutUsPage(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetAboutUsPage = createAsyncThunk('GetAboutUsPage', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAboutPage();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const aboutSlice = createSlice({
    name: 'about',
    initialState: {
        error: null,
        success: false,
        data: [],
        home: null


    },
    reducers: {
        clearStatusAbout: state => {
            state.success = false;
            state.error = null;
        },

    },
    extraReducers: builder => {
        builder
          .addCase(UpdateAboutUs.pending, state => {
            state.error = null;
            state.success = false;
          })
          .addCase(UpdateAboutUs.fulfilled, (state, action) => {
            state.success = action.payload;
            state.error = null;
          })
          .addCase(UpdateAboutUs.rejected, (state, action) => {
            state.error = action.payload;
            state.success = false;
          })
          .addCase(GetAboutUsPage.pending, state => {
            state.error = null;
            state.success = false;
          })
          .addCase(GetAboutUsPage.fulfilled, (state, action) => {
            state.success = action.payload;
            state.error = null;
          })
          .addCase(GetAboutUsPage.rejected, (state, action) => {
            state.error = action.payload;
            state.success = false;
          })
    },
});
export const { clearStatusAbout } = aboutSlice.actions;
export default aboutSlice.reducer;

