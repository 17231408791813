import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import {
  AddSlider,
  clearStatusHome,
} from "../../../../../../Reducer/PagesContentSlices/homeSlice";
import Swal from "sweetalert2";
import Layout from "../../../../../Layouts/Layout";

export default function Add() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [fileData, setData] = useState();
  const [inputField, setInputField] = useState({
    title: "",
    image: null,
  });
  const success = useSelector((state) => state.home.success);
  const error = useSelector((state) => state.home.error);

  function handleChange(e) {
    if (e.target.name == "image") {
      setInputField({
        ...inputField,
        [e.target.name]: e.target.files[0],
      });
      setFile(URL.createObjectURL(e.target.files[0]));
    } else {
      setInputField({
        ...inputField,
        [e.target.name]: e.target.value,
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("title", inputField.title);
      formData.append("image", inputField.image);
      dispatch(AddSlider(formData));
    } catch (e) {
      console.error("Error during login:", e);
    }
  };

  if (success && success == true) {
    Swal.fire({
      title: "Congratulation!",
      text: "Data Added Successfully",
      icon: "success",
    });
    dispatch(clearStatusHome());
    setTimeout(() => {
      navigate("/Pages/Sliders");
    }, 2000);
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearStatusHome());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearStatusHome());
    }
  }

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add Slider</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Image</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      name="image"
                      accept="image/*, image/gif"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                    {file && <img height={200} width={480} src={file} />}
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Title</label>
                    <InputText
                      id="lastname2"
                      type="text"
                      onChange={handleChange}
                      name="title"
                      required
                    />
                  </div>
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
