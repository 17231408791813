import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { sidebarData } from "../../Pages/Data";
import { clearUser } from "../../../Reducer/authSlice";


export default function Sidebar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isActive, setIsActive] = useState(null);

    const openPage = (i) => {
        setIsActive(i);
        let link = sidebarData[i].link;
        navigate(link);
    }

    console.log('location.pathname', location.pathname);

    const logout = () => {
        dispatch(clearUser());
        setTimeout(() => {
            navigate("/");
        }, 1000);

    }

    return (
        <>
            <div class="side_bar match_dash">
                <div class="logo closed_bar">
                    <img class="show" src="/images/logo.png" alt="" />
                </div>
                <ul class="mt-5">
                    {sidebarData.length > 0 && sidebarData.map((item, i) => (
                        <>
                            {
                                item.link ?
                                    <li key={i} >

                                        <Link to={item.link} className={location.pathname === item.link ? "active" : location.pathname.startsWith(item.link) ? "active" : ""}>
                                            {item.name}
                                        </Link>
                                    </li> :


                                    item.dropdown && (
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span><i class="bi bi-people"></i></span> {item.dropdown.label}
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                {item.dropdown.items.map((dropdownItem, index) => (
                                                    <li><Link to={dropdownItem.link} class="dropdown-item"> {dropdownItem.name}</Link></li>
                                                ))}

                                            </ul>
                                        </li>

                                    )
                            }
                        </>
                    ))}
                    <li onClick={() => logout()}><a>
                        <label>Log out</label></a>
                    </li>

                </ul>
            </div>


        </>

    );
}
