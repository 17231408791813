import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Button } from 'primereact/button';
        
import Swal from "sweetalert2";
import Sidebar from "../../../Layouts/Sidebar";
import {UpdateRequestUserList, clearUserRes,ApproveUpdateRequest } from "../../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../../Reducer/api";

import Layout from "../../../Layouts/Layout";
import DynamicDataTable from "../../../../Utils/DynamicDataTable";
import MatchMakerModal from "./../MatchMakerModal";
export default function FieldChangeRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
 
    image: null,
  });
  const [open, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const [file, setFile] = useState();
  const users = useSelector((state) => state.user.data);
  const success = useSelector((state) => state.user.success);
  const error = useSelector((state) => state.user.error);

  if (success && success.status == 200) {
    console.log("successs", success);
    if (success.data.data) {
      dispatch(clearUserRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearUserRes());
      dispatch(UpdateRequestUserList());
    }
  }

  if (error && error.status !== 200) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearUserRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearUserRes());
    }
  }
  
  useEffect(() => {
    dispatch(UpdateRequestUserList());
  }, []);
  const Imagepro=({record,API_BASE_URL})=>{
    return (
      <div>
          {record && record.profileImage && (
                  <img
                      src={API_BASE_URL + record.profileImage}
                      alt="Profile"
                      style={{ width: "100px", height: "auto" }}
                  />
              )}
      </div>
      
    )
  }
  const OpenModal = (id) => {
    setId(id);
    setOpenModal(true);
}
  const columns = [
    {
        
        field: "profileImage",
        header: 'Image',
        body: (rowData) => <Imagepro record={rowData} API_BASE_URL={API_BASE_URL} />
    
    },
    {
       
        field: "name",
        header: "Name",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "email",
        header: "Email",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "mobile",
        header: "Mobile",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "dob",
        header: "dob",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "gender",
        header: "gender",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "Mother_tongue",
        header: "Mother_tongue",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
      field: "birth_place",
      header: "place of birth",
    
    right: true,
    filter: true, filterPlaceholder: 'Search by place', style: { minWidth: '12rem' }
    },
    {
        
      field: "",
      header: "Profile Update",
      filter: false, style: { minWidth: '12rem' },
      body: (record) => (
      <>
                    <Button label="Approve" severity="Primary" onClick={() => UpdateRequestApprove(record.uid,record._id,)} />
        
      </>
    ),
  }
  ];

  function delUser(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
    //   dispatch(DeleteUser(data));
    }
  }
  async function UpdateRequestApprove (uid,id) {
    let data = { _id: id,uid:uid };
    dispatch(ApproveUpdateRequest(data));
    setTimeout(()=>{
        dispatch(UpdateRequestUserList());
    },1000)
   
}
const handleSubmit = (e) => {
  e.preventDefault();
  try {
    let formData = new FormData();
   
    formData.append("image", inputField.image);
    // dispatch(uploadUserExelData(formData));
  } catch (e) {
    console.error("Error during login:", e);
  }
};
const  handleChange=(e) =>{
  if (e.target.name == "image") {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.files[0],
    });
    setFile(URL.createObjectURL(e.target.files[0]));
  } 
}
  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
          
            <div className="card">
              <h5>Field Change Request List</h5>
              <DynamicDataTable
                data={users}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
            />
              
            </div>
          </div>
        </div>
        {open === true &&
                            <MatchMakerModal setOpenModal={setOpenModal} id={id} status={open} />
                        }
      </Layout>
     
    </>
  );
}
