import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Sidebar from "../../Layouts/Sidebar";
import { API_BASE_URL } from "../../../Reducer/api";
import DataTable from "react-data-table-component";
import { Button } from 'primereact/button';
import DynamicDataTable from "../../../Utils/DynamicDataTable";
import {
  ApproveMatchMaker,
  Delmatchmaker,
  MatchMakerList,
  clearMakerRes,
} from "../../../Reducer/matchMakerSlice";
import Layout from "../../Layouts/Layout";

export default function MatchMakers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state) => state.maker.data);
  const success = useSelector((state) => state.maker.success);
  const error = useSelector((state) => state.maker.error);

  if (success && success.status == 200) {
    console.log("successs", success);
    if (success.data.data) {
      dispatch(clearMakerRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearMakerRes());
      dispatch(MatchMakerList());
    }
  }

  if (error && error.status !== 200) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearMakerRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearMakerRes());
    }
  }

  useEffect(() => {
    dispatch(MatchMakerList());
  }, []);

  const columns = [
    {
      header: "Image",
      field: "profileImage",
      body: (record) => (
        <img
          src={API_BASE_URL + record.profileImage}
          alt="Profile"
          style={{ width: "100px", height: "auto" }}
        />
      ),
    },
    {
      header: "Email",
      field: "email",
      right: true,
    },
    {
      header: "Email",
      field: "email",
      right: true,
    },
    {
      header: "Mobile",
      field: "mobile",
      right: true,
    },
    {
      header: "Action",
      body: (record) => (
        <>
          <Link to={`/MatchMaker/${record._id}`}>
            <i className="fa fa-eye"></i>
            <Button label="Info" severity="info" icon="pi pi-eye" />
          </Link>
          <a onClick={() => delUser(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
        </>
      ),
    },
    {
      header: "Profile Status",
      body: (record) => (
        <>
          {record.profile_status && record.profile_status === "Approved" ? (
            <Button label="Approved" severity="success"  />
            
          ) : (
            <Button label="Approve" severity="Primary"  onClick={() => ApproveProfile(record._id)} />
           
          )}
        </>
      ),
    },
  ];

  function delUser(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
      dispatch(Delmatchmaker(data));
    }
  }

  function ApproveProfile(id) {
    let data = { id: id };
    dispatch(ApproveMatchMaker(data));
  }

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Match Maker List</h5>
              <DynamicDataTable
                data={users}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
              />
            </div>
          </div>
        </div>
        
      </Layout>
    </>
  );
}
