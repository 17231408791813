

import {  Link,useNavigate, useLocation} from "react-router-dom";
import classNames from 'classnames';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { Menu } from 'primereact/menu';
import {LayoutContext} from "../../context/layoutcontext"
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from "../../Reducer/authSlice";
import {LOGO} from "../../Constant/constants"

const AppTopbar = forwardRef((props, ref) => {
    const { layoutConfig, layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    const menuRight = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));
    const logout = () => {
        dispatch(clearUser());
        setTimeout(() => {
            navigate("/");
        }, 1000);

    }
    const items = [
        {
            label: '',
            items: [
                {
                    template: (item, options) => {
                        return (
                            
                                <div class="p-menuitem-content" data-pc-section="content">
                                    <a href="#" onClick={() => logout()} class="p-menuitem-link" tabindex="-1" aria-label="Logout" aria-hidden="true" data-pc-section="action"><span class="p-menuitem-icon pi pi-sign-out" data-pc-section="icon"></span><span class="p-menuitem-text" data-pc-section="label">Logout</span></a>
                                    <a href="#" onClick={() => navigate("/changepassword")} class="p-menuitem-link" tabindex="-2" aria-label="Logout" aria-hidden="true" data-pc-section="action"><span class="p-menuitem-text" data-pc-section="label">change password</span></a>
                                    </div>
                            
                            
                        );
                    }
                }
            ]
        }
    ];

    return (
        <div className="layout-topbar">
            <Link href="/" className="layout-topbar-logo">
                <img src={`/images/${LOGO}`} width="100%" height={'35px'} alt="logo" />
               
            </Link>

            <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
                <i className="pi pi-bars" />
            </button>

            <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
                {/* <button type="button" className="p-link layout-topbar-button">
                    <i className="pi pi-calendar"></i>
                    <span>Calendar</span>
                </button> */}
                <button type="button" className="p-link layout-topbar-button" onClick={(event) => menuRight.current.toggle(event)}>
                    <i className="pi pi-user"></i>
                    <span>Profile</span>
                </button>
                <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                {/* <Link href="/documentation">
                    <button type="button" className="p-link layout-topbar-button">
                        <i className="pi pi-cog"></i>
                        <span>Settings</span>
                    </button>
                </Link> */}
            </div>
        </div>
    );
});



export default AppTopbar;
