import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CouponAdd, GetAllCoupons, DeleteCoupon } from './api';

export const SaveCoupon = createAsyncThunk('SaveCoupon', async (formData, { rejectWithValue }) => {
    try {
        const response = await CouponAdd(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetCoupon = createAsyncThunk('GetCoupons', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllCoupons();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DelCoupon = createAsyncThunk('DelllCoupons', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteCoupon(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const couponSlice = createSlice({
    name: 'cop',
    initialState: {
        error: null,
        success: false,
        data: []
    },
    reducers: {
        clearCop: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(SaveCoupon.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(SaveCoupon.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SaveCoupon.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetCoupon.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetCoupon.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetCoupon.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DelCoupon.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DelCoupon.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DelCoupon.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
    },
});
export const { clearCop } = couponSlice.actions;
export default couponSlice.reducer;

