import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { GetGovtDocs, VerifySelfie, clearUserRes } from "../../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../../Reducer/api";


export default function Documents() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const data = useSelector(state => state.user.GovtDoc);
    const success = useSelector(state => state.user.success);
    const error = useSelector(state => state.user.error);

    


    useEffect(() => {
        getData();

    }, []);

    async function getData() {
        let dts = { uid: params.id };
        dispatch(GetGovtDocs(dts));
    }

    const ClickToVerify = () => {
        console.log('click', );
        let res = { uid: params.id, type: "Docs" };
        dispatch(VerifySelfie(res));

    }
    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearUserRes());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.message,
                icon: 'success'
            });
            dispatch(clearUserRes());
            getData();
        }


    }

    if (error && error.status !== 200) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearUserRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearUserRes());
        }
    }


    return (
        <>

            <div className="basic_sec">

                <h3>Uploaded Documents</h3>
                {data && data.front_img && data.back_img ?

                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Front Side</label>
                        {data && data.front_img &&  <img src={data && API_BASE_URL + data.front_img} alt=""  width="70%" height="70%"/>}
                       
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="form-label">Back Side</label>
                        {data && data.back_img && <img src={data && API_BASE_URL + data.back_img} alt="" width="70%" height="70%" />}
                    </div>
                </div>
                : <p>Documents not uploaded</p>}
                {data && data.verified === true && <button class="btn" >Verified</button>} {data && data.verified === false && <button class="btn" onClick={() => ClickToVerify()}>Click to Verify</button>}


            </div>


        </>

    );
}
