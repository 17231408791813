import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { AssignUser, GetApprovedMakers, clearModal } from "../../../Reducer/modalSlices/makerModalSlice";
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
export default function MatchMakerModal({ setOpenModal, id,status }) {
    
    const dispatch = useDispatch();
    const [makerId, setMakerId] = useState(null);
    const [visible, setVisible] = useState(status);
    const data = useSelector(state => state.makerModal.data);
    const success = useSelector(state => state.makerModal.success);
    const error = useSelector(state => state.makerModal.error);
    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clearModal());
        } else {
            Swal.fire({
                title: 'Success!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clearModal());
            setOpenModal(false);

        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearModal());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearModal());
        }
    }


    console.log('ddddd', data);

    useEffect(() => {
        getDataaaaa();
    }, []);

    const getDataaaaa = () => {
        dispatch(GetApprovedMakers());

    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleInputChange = (e) => {
        setMakerId(e.target.value);

    }

    const SubmitAssign = (e) => {
        // e.preventDefault();
        if (makerId === null) {
            alert("Please select Match Maker");
        } else {
            let dat = { uid: id, matchMakerId: makerId };
            dispatch(AssignUser(dat));

        }
    }


    return (
        <>
        <Dialog header="Assign User to MatchMaker" visible={status} style={{ width: '50vw' }} onHide={() => {setOpenModal(false); }}>
        <div class="modal-body">
                            
                            <h3>Please select MatchMaker</h3>

                            <ul>
                                {data.length > 0 && data.map((item, i) => (
                                  
                                        <div class="form-check">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name="block_reason" value={item._id} onChange={handleInputChange}  checked={makerId === item._id} />
                                            <label htmlFor="ingredient1" className="ml-2">{item.name}</label>
                                        </div>
                                            
                                        </div>
                                    
                                ))}

                            </ul>



                        </div>
                        <div class="modal-footer">
                        <Button label="Cancel" severity="secondary" onClick={() => handleCloseModal()} style={{marginRight: "2%"}}/>
                         
                        <Button label="Confirm" severity="success" onClick={() => SubmitAssign()} />
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleCloseModal()}>Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={() => SubmitAssign()}><i class="fa-solid fa-ban me-2"></i>Confirm
                            </button> */}
                        </div>
        </Dialog>
            
        </>
    )
}