import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import Sidebar from "../../../Layouts/Sidebar";
import {
  AddHome,
  GetHome,
  clearStatusHome,
} from "../../../../Reducer/PagesContentSlices/homeSlice";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../../Reducer/api";
import Layout from "../../../Layouts/Layout";

export default function HomePage() {
  const dispatch = useDispatch();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();
  const [file4, setFile4] = useState();
  const [file5, setFile5] = useState();
  const [file6, setFile6] = useState();
  const [update, setupdate] = useState(false);
  const [localData, setLocalData] = useState(null);
  const success = useSelector((state) => state.home.success);
  const error = useSelector((state) => state.home.error);
  const data = useSelector((state) => {
    var value = state.home.home;
    if (value != null && !update) {
      setLocalData(value);
      setupdate(true);
    } else {
    }
    return value;
  });

  console.log("data", data);

  useEffect(() => {
    let id = { id: "Home" };
    dispatch(GetHome(id));
  }, []);

  function handleChange(e) {
    if (e.target.name == "background_img") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile1(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name == "banner_image") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile2(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name == "img1") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile3(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name == "img2") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile4(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name == "img3") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile5(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name == "img4") {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.files[0],
      });
      setFile6(URL.createObjectURL(e.target.files[0]));
    } else {
      setLocalData({
        ...localData,
        [e.target.name]: e.target.value,
      });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      console.log("dataaaaaa", data);

      let formData = new FormData();
      formData.append("background_img", localData.background_img);
      formData.append("banner_image", localData.banner_image);
      formData.append("img1", localData.img1);
      formData.append("img2", localData.img2);
      formData.append("img3", localData.img3);
      formData.append("img4", localData.img4);
      formData.append("text1", localData.text1);
      formData.append("text2", localData.text2);
      formData.append("text3", localData.text3);
      formData.append("text4", localData.text4);
      formData.append("meta_tag", localData.meta_tag);
      formData.append("meta_description", localData.meta_description);
      formData.append("slug", localData.slug);
      formData.append("id", localData._id);
      formData.append("pageName", "Home");
      dispatch(AddHome(formData));
    } catch (e) {
      console.error("Error during login:", e);
    }
  };

  if (success && success.status == 200) {
    if (success.data.data) {
      console.log("success underr data", success);
      dispatch(clearStatusHome());
    } else {
      Swal.fire({
        title: "Success!",
        text: "Data updated successfully",
        icon: "success",
      });
      console.log("success underr data", success);
      dispatch(clearStatusHome());
    }
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearStatusHome());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearStatusHome());
    }
  }

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Home Page</h5>
              {localData &&
                localData != null &&
                Object.keys(localData).length > 0 && (
                  <form onSubmit={handleSubmit}>
                    <div className="p-fluid formgrid grid">
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Background Image</label>
                        <InputText
                          id="firstname2"
                          type="file"
                          name="background_img"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file1 ? (
                          <img src={file1} height={200} width={480} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + localData.background_img}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Banner Image</label>
                        <InputText
                          id="lastname2"
                          type="file"
                          name="banner_image"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file2 ? (
                          <img src={file2} height={200} width={480} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + localData.banner_image}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Text 1</label>
                        <InputText
                          id="firstname2"
                          type="text"
                          name="text1"
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.text1}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 2</label>
                        <InputText
                          id="lastname2"
                          type="text"
                          name="text2"
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.text2}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="firstname2">Image 1</label>
                        <InputText
                          id="firstname2"
                          type="file"
                          name="img1"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file3 ? (
                          <img src={file3} height={200} width={480} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + localData.img1}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Image 2</label>
                        <InputText
                          id="lastname2"
                          type="file"
                          name="img2"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file4 ? (
                          <img height={200} width={480} src={file4} />
                        ) : (
                          <img
                            height={200}
                            width={480}
                            src={API_BASE_URL + localData.img2}
                          />
                        )}
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 3</label>
                        <InputText
                          type="text"
                          name="text3"
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.text3}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Text 4</label>
                        <InputText
                          type="text"
                          name="text4"
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.text4}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Image 3</label>
                        <InputText
                          type="file"
                          name="img3"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file5 ? (
                          <img src={file5} />
                        ) : (
                          <img src={API_BASE_URL + localData.img3} />
                        )}
                      </div>

                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Image 4</label>
                        <InputText
                          type="file"
                          name="img4"
                          accept="image/*"
                          onChange={(e) => handleChange(e)}
                        />
                        {file6 ? (
                          <img src={file6} />
                        ) : (
                          <img src={API_BASE_URL + localData.img4} />
                        )}
                      </div>

                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Tag</label>
                        <InputText
                          type="text"
                          name="meta_tag"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.meta_tag}
                        />
                      </div>
                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Meta Description</label>
                        <InputTextarea
                          type="text"
                          name="meta_description"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.meta_description}
                        />
                      </div>

                      <div className="field col-12 md:col-6">
                        <label htmlFor="lastname2">Slug</label>
                        <InputText
                          type="text"
                          name="slug"
                          required
                          onChange={(e) => handleChange(e)}
                          defaultValue={localData.slug}
                        />
                      </div>

                      <div class="col-12">
                        <div className="md:col-3">
                          <Button
                            label="Submit"
                            type="submit"
                            severity="Primary"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
