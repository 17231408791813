import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllApprovedMatchMakers, AssignUserToMatchMaker } from '../api';

export const GetApprovedMakers = createAsyncThunk('GetApprovedMakers', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllApprovedMatchMakers();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const AssignUser = createAsyncThunk('AssignUser', async (formData, { rejectWithValue }) => {
    try {
        const response = await AssignUserToMatchMaker(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const makerModalSlice = createSlice({
    name: 'makerModal',
    initialState: {
        error: null,
        success: false,
        data: []

    },
    reducers: {

        clearModal: state => {
            state.error = null;
            state.success = false;
        },



    },
    extraReducers: builder => {
        builder
            .addCase(GetApprovedMakers.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetApprovedMakers.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(GetApprovedMakers.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(AssignUser.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(AssignUser.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(AssignUser.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })


    },
});
export const { clearModal } = makerModalSlice.actions;
export default makerModalSlice.reducer;

