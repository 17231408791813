import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddKootas, GetKundaliPage, DeleteGun, UpdateKundaliPage } from '../api';


export const AddKootasLi = createAsyncThunk('add AddKootasList', async (formData, { rejectWithValue }) => {
    try {
        const response = await AddKootas(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const GetkundaliContent = createAsyncThunk('get GetkundaliContent', async (formData, { rejectWithValue }) => {
    try {
        const response = await GetKundaliPage(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const DeleteGunss = createAsyncThunk('del DeleteGun', async (formData, { rejectWithValue }) => {
    try {
        const response = await DeleteGun(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const UpdateKnPage = createAsyncThunk('editt UpdateKundaliPage', async (formData, { rejectWithValue }) => {
    try {
        const response = await UpdateKundaliPage(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const kundaliMatchingSlice = createSlice({
    name: 'kundaliPg',
    initialState: {
        error: null,
        success: false,
        data: null

    },
    reducers: {
        clearKnRes: state => {
            state.success = false;
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(AddKootasLi.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(AddKootasLi.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(AddKootasLi.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(GetkundaliContent.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(GetkundaliContent.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = null;
                state.error = null;
            })
            .addCase(GetkundaliContent.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(DeleteGunss.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DeleteGunss.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DeleteGunss.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
            .addCase(UpdateKnPage.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(UpdateKnPage.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(UpdateKnPage.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })
    },
});
export const { clearKnRes } = kundaliMatchingSlice.actions;
export default kundaliMatchingSlice.reducer;

