import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Sidebar from "../../../Layouts/Sidebar";
import { SaveCoupon, clearCop } from "../../../../Reducer/couponSlice";
import Layout from "../../../Layouts/Layout";
import { InputText } from "primereact/inputtext";

import { Editor } from "primereact/editor";

import { InputTextarea } from "primereact/inputtextarea";

import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
export default function AddCoupon() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    coupon_type: "Site Launch/ New User Code",
  });
  const success = useSelector((state) => state.cop.success);
  const error = useSelector((state) => state.cop.error);

  const generateCouponCode = () => {
    const possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const couponCodeLength = 6;

    let couponCode = "";
    for (let i = 0; i < couponCodeLength; i++) {
      couponCode += possibleCharacters
        .charAt(Math.floor(Math.random() * possibleCharacters.length))
        .toUpperCase();
    }
    setFormData({
      ...formData,
      ["code"]: couponCode,
    });

    // setCouponCode(couponCode);
  };

  console.log("susss", success);

  if (success && success.status === 200) {
    if (success.data.data) {
      dispatch(clearCop());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearCop());
      navigate("/Coupons");
    }
  }

  if (error && error.status) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearCop());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearCop());
    }
  }

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    let form = new FormData();
    form.append("coupon_type", formData.coupon_type.code);
    form.append("code", formData.code);
    form.append("percentage", formData.percentage);
    form.append("start_date", new Date(formData.start_date));
    form.append("end_date", new Date(formData.end_date));
    form.append("type", "Promo");

    dispatch(SaveCoupon(form));
    console.log("formdataa", formData);
  };

  useEffect(() => {}, []);
  const CouponType = [
    { name: 'Site Launch/ New User Code', code: 'Site Launch/ New User Code' },
    { name: 'Special Reccomendation Code', code: 'Special Reccomendation Code' },
    { name: 'Seasonal / Festival Code', code: 'Seasonal / Festival Code' },
    { name: 'Referal Code', code: 'Referal Code' },
   
];
  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>Add New Coupon</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Coupon Type</label>
                    <Dropdown value={formData.coupon_type} onChange={handleInputChange} options={CouponType} optionLabel="name" 
    placeholder="Select Coupon Type" className="w-full md:w-14rem"  name="coupon_type" />
                   
                    
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Discount Percentage</label>
                    <InputText
                      id="firstname2"
                      type="number"
                      min="0"
                      onChange={handleInputChange}
                      name="percentage"
                      required
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">Code</label>
                    <InputText
                      id="firstname2"
                      type="text"
                      onChange={handleInputChange}
                      name="code"
                      required
                      value={formData && formData.code}
                    />
                    <Button onClick={generateCouponCode}>Generate</Button>
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">Start Date</label>
                    <InputText
                      id="firstname2"
                      type="date"
                      onChange={handleInputChange}
                      name="start_date"
                      required
                      value={formData && formData.start_date}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="lastname2">End Date</label>
                    <InputText
                      id="firstname2"
                      type="date"
                      onChange={handleInputChange}
                      name="end_date"
                      required
                      value={formData && formData.end_date}
                    />
                  </div>
                 
                  

                 
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      
    </>
  );
}
