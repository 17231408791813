import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAdmin,ChangePassword } from './api';

export const LoginAdmin = createAsyncThunk('Loginnn admin', async (formData, { rejectWithValue }) => {
  try {
    
    const response = await loginAdmin(formData);
    localStorage.setItem('user', JSON.stringify(response.data.data));
    sessionStorage.setItem('useremail', JSON.stringify(response.data.data.email));
    localStorage.setItem('token', "12345678");
    // localStorage.setItem('token', response.data.token);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const changePassword= createAsyncThunk('Change Password', async (formData, { rejectWithValue }) => {
  try {
    
    const user = JSON.parse(localStorage.getItem('user'));

    // Check if user is available and has an ID
    if (user && user._id) {
      // Add user ID to formData
      formData.append('email', user.email);
    } else {
      throw new Error('User data is not available or user ID is missing');
    }
    const response = await ChangePassword(formData);
    // localStorage.setItem('user', JSON.stringify(response.data.data));
    // localStorage.setItem('token', "12345678");
    // localStorage.setItem('token', response.data.token);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    error: null,
    success: false,
    auth: {},
    token: localStorage.getItem('token') || "",
    data: null,
    logincheck:sessionStorage.getItem('useremail') ?true :false,

  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },

    clearUser: state => {
      state.auth = null;
      state.success = false;
      state.logincheck=false;
      // state.token = "";
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },

    clearStatus: state => {
      state.success = false;
      state.error = null;
    },

  },
  extraReducers: builder => {
    builder
      .addCase(LoginAdmin.pending, state => {
        state.error = null;
        state.success = false;
        state.logincheck=false
      })
      .addCase(LoginAdmin.fulfilled, (state, action) => {
        
        state.auth = action.payload.data;
        state.success = action.payload;
        state.error = null;
        state.logincheck=true;
      })
      .addCase(LoginAdmin.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
        state.logincheck=false;
      })
      .addCase(changePassword.pending, state => {
        state.error = null;
        state.success = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.auth = action.payload.data;
        state.success = action.payload;
        state.error = null;
        state.logincheck=true
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
      })
  },
});
export const { setUser, clearStatus, clearUser } = authSlice.actions;
export default authSlice.reducer;

