import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './Reducer/authSlice';
import pagesSlice from './Reducer/PagesContentSlices/pagesSlice';
import storySlice from './Reducer/PagesContentSlices/storySlice';
import homeSlice from './Reducer/PagesContentSlices/homeSlice';
import userSlice from './Reducer/userSlice';
import dashboardSlice from './Reducer/dashboardSlice';
import kunaliMatchingSlice from './Reducer/PagesContentSlices/kunaliMatchingSlice';
import aboutSlice from './Reducer/PagesContentSlices/aboutSlice';
import instructorSlice from './Reducer/PagesContentSlices/instructorSlice';
import faqSlice from './Reducer/PagesContentSlices/faqSlice';
import querySlice from './Reducer/querySlice';
import matchMakerSlice from './Reducer/matchMakerSlice';
import makerModalSlice from './Reducer/modalSlices/makerModalSlice';
import reportedSlice from './Reducer/reportedSlice';
import couponSlice from './Reducer/couponSlice';
import SubscriptionSlice from './Reducer/SubscriptionSlice';

const rootReducer = combineReducers({
    auth: authSlice,
    pages: pagesSlice,
    story: storySlice,
    home: homeSlice,
    user: userSlice,
    dashboard: dashboardSlice,
    kundaliPg: kunaliMatchingSlice,
    about: aboutSlice,
    instructor: instructorSlice,
    faq: faqSlice,
    query: querySlice,
    maker: matchMakerSlice,
    makerModal: makerModalSlice,
    report: reportedSlice,
    cop: couponSlice,
    plan: SubscriptionSlice
});
export default rootReducer;